import { Navigate, Outlet } from "react-router-dom";
import MainLayout from "../views/layouts/main";
import SignIn from "../views/signIn";
import Dashboard from "../views/dashboard";
import NotFound from "../views/notFound";
import Users from "../views/users";
import Coupon from "../views/coupons";
import UserStories from "../views/userStories";
import Referals from "../views/Referals";
import DemoCall from "../views/demoCall";
import UserTracking from "../views/userTracking";
import RevenueDashboard from "../views/revenue/revenueDashboard";
const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ) : (
      <Navigate to="/login" />
    ),
    children: [
      { path: "/", element: <Dashboard /> },
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/coupon", element: <Coupon /> },
      // {
      //   path: "/company",
      //   element: <Outlet />,
      //   children: [
      //     { path: "", element: <Members /> },
      //     { path: "teams", element: <Members /> },
      //     { path: "members", element: <Members /> },
      //     { path: "invitemember", element: <Members /> },
      //   ],
      // },
      { path: "/users", element: <Users /> },
      { path: "/user-stories", element: <UserStories /> },
      { path: "/user-tracking", element: <UserTracking /> },
      { path: "/referals", element: <Referals /> },
      { path: "/demo", element: <DemoCall /> },
    ],
  },
  {
    path: "/",
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/" />,
    children: [
      { path: "login", element: <SignIn /> },
      { path: "/", element: <Navigate to="/login" /> },
    ],
  },
  {path:"/revenue",element:<RevenueDashboard />},
  { path: "/404", element: <NotFound /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
