import React, { useEffect, useState } from "react";
import EventBus from "eventing-bus";
import { USER_TYPES } from "../../redux/types";
import { connect } from "react-redux";
import { encryptData, getDecryptedData } from "../../utility/utils";
import {
  updateThemeModeSuccess,
  requestSignInUser,
  requestOtpVerify,
} from "../../redux/actions";
import {
  SignInStyledComponent,
  SignInLeftSideBox,
  SignInRightSideBox,
} from "./signIn.styled";
import { Input, Button } from "../../theme/components";
import { Validate } from "../../helpers";
import VerifyOtp from "./verifyOtp";
import LoginPageLeftSideImg from "../../assets/images/loginpageleftsideimg.svg";

const formData = { email: "", password: "" };

function SignIn(props) {
  const [form, setForm] = useState(formData);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [verificationToken, setVerificationToken] = useState("");

  useEffect(() => {
    const requestSignIn = EventBus.on(USER_TYPES.SIGN_IN_REQUEST, () =>
      setIsLoading(true)
    );
    const signInSuccess = EventBus.on(USER_TYPES.SIGN_IN_SUCCESS, (res) => {
      setIsLoading(false);
      setIsFormSubmit(false);
      handleSignInSuccessResponse(getDecryptedData(res.data));
    });
    const signInFailure = EventBus.on(USER_TYPES.SIGN_IN_FAILED, () => {
      setIsLoading(false);
      setIsFormSubmit(false);
    });

    return () => {
      requestSignIn();
      signInSuccess();
      signInFailure();
    };
  }, []);

  const handleSignInSuccessResponse = (res) => {
    if (res?.isOtpRequired) {
      setVerificationToken(res?.details);
    }
  };

  const handleValidation = () => {
    let errorStructure = {
      errorEmail: "",
      errorPassword: "",
      isValidated: false,
    };

    if (!isFormSubmit) return errorStructure;

    if (!form?.email) {
      errorStructure = { ...errorStructure, errorEmail: "Please enter email" };
    } else if (!Validate.email(form?.email)) {
      errorStructure = {
        ...errorStructure,
        errorEmail: "Please enter valid email",
      };
    }
    if (!form?.password) {
      errorStructure = {
        ...errorStructure,
        errorPassword: "Please enter password",
      };
    } else if (!Validate.minLength(form?.password, 3)) {
      errorStructure = {
        ...errorStructure,
        errorPassword: "Password must be minimum 3 characters",
      };
    }

    if (!errorStructure?.errorEmail && !errorStructure?.errorPassword) {
      errorStructure = {
        ...errorStructure,
        isValidated: true,
      };
    }

    return errorStructure;
  };

  useEffect(() => {
    if (isFormSubmit) {
      handleSubmit();
    }
  }, [isFormSubmit]);

  const onSubmit = () => {
    setIsFormSubmit(Math.random());
  };

  const handleSubmit = () => {
    const { isValidated } = handleValidation();

    if (!isValidated) return;
    const params = { ...form };
    props.requestSignInUser({data: encryptData(params)});
  };

  const handleChange = (e) => {
    setVerificationToken("");
    setForm({
      ...form,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const { errorEmail, errorPassword } = handleValidation();
  return (
    <SignInStyledComponent>
      <SignInLeftSideBox>
        <img src={LoginPageLeftSideImg} alt="login" />
      </SignInLeftSideBox>
      <SignInRightSideBox>
        <div>
          <h1>Welcome to QwikSkills!👋</h1>
          <br />
          <h5>Please sign-in to your account and start the adventure</h5>
          <br />
          <Input
            label="Email"
            type="email"
            onChange={handleChange}
            name="email"
            value={form.email}
            isError={Boolean(errorEmail)}
            errorMessage={errorEmail}
            disabled={isLoading}
          />
          <br />
          <Input
            label="Password"
            type="password"
            onChange={handleChange}
            value={form.password}
            name="password"
            isError={Boolean(errorPassword)}
            errorMessage={errorPassword}
            disabled={isLoading}
          />
          <br />
          {verificationToken ? (
            <VerifyOtp
              verificationToken={verificationToken}
              signInFormData={form}
            />
          ) : (
            <Button onClick={onSubmit} disabled={isLoading} small>
              Sign In{isLoading && "..."}
            </Button>
          )}
        </div>
      </SignInRightSideBox>
    </SignInStyledComponent>
  );
}

function mapStateToProps(state) {
  return {
    theme: state?.theme,
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  updateThemeModeSuccess,
  requestSignInUser,
  requestOtpVerify,
})(SignIn);
