export const FONT_SIZES = {
  H1: "34px",
  H2: "30px",
  H3: "26px",
  H4: "22px",
  H5: "18px",
  H6: "16px",
  SUB_TITLE: "22px",
  BODY_LARGE: "18px",
  BODY_MEDIUM_1: "16px",
  BODY_MEDIUM_2: "16px",
  BODY_SMALL_1: "15px",
  BODY_SMALL_2: "14px",
  BODY_EXTRA_SMALL_1: "12px",
  BODY_EXTRA_SMALL_2: "12px",
  BUTTON_LARGE: "15px",
  BUTTON_SMALL: "13px",
  LINK: "14px",
};
