import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import EventBus from "eventing-bus";
import { requestOtpVerify } from "../../../redux/actions";
import { USER_TYPES } from "../../../redux/types";
import { VerifyModalBox } from "./verifyOtp.styled";
import { Button, Input } from "../../../theme/components";
import { Validate } from "../../../helpers";

const formData = { otp: "" };

function VerifyOtpModal(props) {
  const [form, setForm] = useState(formData);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  useEffect(() => {
    const requestOtp = EventBus.on(USER_TYPES.OTP_VERIFY_REQUEST, () =>
      setIsLoading(true)
    );
    const otpSuccess = EventBus.on(USER_TYPES.OTP_VERIFY_SUCCESS, () => {
      setIsLoading(false);
      setIsFormSubmit(false);
    });
    const otpFailure = EventBus.on(USER_TYPES.OTP_VERIFY_FAILED, () => {
      setIsLoading(false);
      setIsFormSubmit(false);
    });

    return () => {
      requestOtp();
      otpSuccess();
      otpFailure();
    };
  }, []);

  const handleValidation = () => {
    let errorStructure = {
      errorOtp: "",
      isValidated: false,
    };

    if (!isFormSubmit) return errorStructure;

    if (!form?.otp) {
      errorStructure = {
        ...errorStructure,
        errorOtp: "Please enter otp",
      };
    } else if (!Validate.equalLength(form?.otp, 6)) {
      errorStructure = {
        ...errorStructure,
        errorOtp: "otp must be 6 characters",
      };
    }

    if (!errorStructure?.errorOtp) {
      errorStructure = {
        ...errorStructure,
        isValidated: true,
      };
    }

    return errorStructure;
  };

  const handleSubmit = () => {
    const { isValidated } = handleValidation();

    if (!isValidated) return;
    const { verificationToken, signInFormData } = props;
    const params = {
      ...form,
      verification_key: verificationToken,
      ...signInFormData,
      source: "VERIFY",
    };
    props.requestOtpVerify(params);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (isFormSubmit) {
      handleSubmit();
    }
  }, [isFormSubmit]);

  const onSubmit = () => {
    setIsFormSubmit(Math.random());
  };

  const { errorOtp } = handleValidation();

  return (
    <VerifyModalBox>
      <Input
        label="Enter otp"
        maxWidth="100%"
        onChange={handleChange}
        name="otp"
        value={form.otp}
        isError={Boolean(errorOtp)}
        errorMessage={errorOtp}
        disabled={isLoading}
      />
      <br />
      <Button onClick={onSubmit} disabled={isLoading}>
        Sign In{isLoading && "..."}
      </Button>
    </VerifyModalBox>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  requestOtpVerify,
})(VerifyOtpModal);
