export const COLORS = {
  blue: "#7B68EE",
  darkBlue: "#5B43EA",
  lightBlue: "#CCC5FF",
  lightBlue1: "#F0EEFF",
  lightBlue2: "#F7F6FE",
  black: "#111111",
  grey01: "#727787",
  grey02: "#B9BEC7",
  grey03: "#E3E7EF",
  grey04: "#F6F6F9",
  grey05: "#FAFAFA",
  white: "#FFFFFF",
  red: "#DC3232",
  green: "#46B450",
  darkPink1: "#FF4F9D",
  darkPink: "#FD71AF",
  lightPink: "#FFEDF5",
  darkGreen1: "#009D71",
  darkGreen: "#00B884",
  lightGreen: "#F0FBF8",
  darkYellow1: "#E5B400",
  darkYellow: "#FFC800",
  lightYellow: "#FFF9EA",
  darkSkyBlue1: "#00B2EF",
  darkSkyBlue: "#49CCF9",
  lightSkyBlue: "#EDFAFE",
  raisinBlack: "#25252E",
  raisinBlack2: "#1C1C23",
  manatee: "#999FB0",
};
