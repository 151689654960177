import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const Loader = styled.div`
  text-align: center;
  padding: 50px 0px;
`;

export const MoreBtn = styled.div`
  text-align: center;
  padding: 20px 0px;
  background: #eeeeff;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #d0c7c7;
  cursor: pointer;
`;

export const UserBox = styled.div`
  padding: 30px 15px 50px 15px;
`;

export const ContentBox = styled.div`
  padding: 20px 15px 0px 15px;
`;

export const RowContentBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0px;
`;

export const ListContentBox = styled.div`
  padding: 8px 20px 10px 20px;
  border: 1px solid black;
  border-radius: 8px;
  min-width: 200px;
  min-height: 40px;
  margin-right: 30px;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
`;
