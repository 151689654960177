import styled from "styled-components";
import { COLORS } from "../../colors";

export const DrawerBox = styled.div`
  .side-drawer-background {
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    ${(props) => props.isFromLeftSide && `left: 0;`};
    z-index: 200;
    background: rgba(0, 0, 0, 0.4);
  }
  .side-drawer {
    min-width: 250px;
    height: 100%;
    width: fit-content;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    ${(props) =>
      props.isFromLeftSide &&
      `left: 0;
      transform: translateX(-100%);`};
    background: ${COLORS.white};
    box-shadow: "1px 0px 7px rgba(0, 0, 0, 0.5)";
  }
  .side-drawer-open {
    transform: translateX(0);
    transition: transform 0.3s ease-out;
  }
`;
