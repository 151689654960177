import { GLOBAL_TYPES } from "../types";

//getCountry List 
export function requestGetCountryList(payload) {
  return {
    type: GLOBAL_TYPES.GET_COUNTRY_LIST_REQUEST,
    payload,
  };
}

export function getCountryListSuccess(payload) {
  return {
    type: GLOBAL_TYPES.GET_COUNTRY_LIST_SUCCESS,
    payload,
  };
}

export function getCountryListFailure(payload) {
  return {
    type: GLOBAL_TYPES.GET_COUNTRY_LIST_FAILED,
    payload,
  };
}

//get Package List 
export function requestGetPackageList(payload) {
  return {
    type: GLOBAL_TYPES.GET_PACKAGE_LIST_REQUEST,
    payload,
  };
}

export function getPackageListSuccess(payload) {
  return {
    type: GLOBAL_TYPES.GET_PACKAGE_LIST_SUCCESS,
    payload,
  };
}

export function getPackageListFailure(payload) {
  return {
    type: GLOBAL_TYPES.GET_PACKAGE_LIST_FAILED,
    payload,
  };
}

//get coupons List 
export function requestGetCouponsList(payload) {
  return {
    type: GLOBAL_TYPES.GET_COUPONS_LIST_REQUEST,
    payload,
  };
}

export function getCouponsListSuccess(payload) {
  return {
    type: GLOBAL_TYPES.GET_COUPONS_LIST_SUCCESS,
    payload,
  };
}

export function getCouponsListFailure(payload) {
  return {
    type: GLOBAL_TYPES.GET_COUPONS_LIST_FAILED,
    payload,
  };
}
