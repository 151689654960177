import React from "react";
import { Modal, Button } from "../../../theme/components";

function RemoveUser(props) {
  const { removeId = "", onClose = () => {} } = props;
  return (
    <div>
      <Modal
        show={Boolean(removeId)}
        onClose={onClose}
        title={<h4>Remove Member</h4>}
        maxWidth="400"
        footer={
          <div>
            <Button>Yes</Button> &nbsp;&nbsp;&nbsp;
            <Button onClick={onClose} outline>
              Cancel
            </Button>
          </div>
        }
      >
        Are you sure to remove Member x? once Member is deleted it can not be
        restored.click on yes to confirm
      </Modal>
    </div>
  );
}

export default RemoveUser;
