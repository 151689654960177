import { takeEvery, call, put } from "redux-saga/effects";
import {
    getRegisteredUsersSuccess,
    getRegisteredUsersFailure,
    getUsersByPaymentDetailsFailure,
    getUsersByPaymentDetailsSuccess,
    getUsersByPackageFailure,
    getUsersByPackageSuccess,
    getLabCoinsCountSuccess,
    getLabCoinsCountFailure,
    getLabsListFailure,
    getLabsListSuccess
  } from "../actions";
  import { DASHBOARD_TYPES } from "../types";
  import { getRegisteredUsers , getUsersByPackage, getUsersByPaymentDeatils , getCloudLabsList , getLabCoinsCount} from "../apis";

  export function* watchDashboardRequests() {
    yield takeEvery(DASHBOARD_TYPES.GET_REGISTERED_USERS_REQUEST, requestGetRegisteredUsers);
    yield takeEvery(DASHBOARD_TYPES.GET_USERS_BY_PAYMENT_DETAILS_REQUEST, requestGetUsersByPaymentDetails);
    yield takeEvery(DASHBOARD_TYPES.GET_USERS_BY_PACKAGE_REQUEST, requestGetUsersByPackage);
    yield takeEvery(DASHBOARD_TYPES.GET_LAB_CREDITS_COUNT_REQUEST, requestGetLabCoinsCount);
    yield takeEvery(DASHBOARD_TYPES.GET_CLOUD_LABS_LIST_REQUEST, requestGetLabsList);
  }

  function* requestGetRegisteredUsers(action) {
    try {
      const data = yield call(getRegisteredUsers, action.payload);
      yield put(getRegisteredUsersSuccess(data));
    } catch (e) {
      yield put(getRegisteredUsersFailure(e));
    }
  }

  function* requestGetUsersByPaymentDetails(action) {
    try {
      const data = yield call(getUsersByPaymentDeatils, action.payload);
      yield put(getUsersByPaymentDetailsSuccess(data));
    } catch (e) {
      yield put(getUsersByPaymentDetailsFailure(e));
    }
  }

  function* requestGetUsersByPackage(action) {
    try {
      const data = yield call(getUsersByPackage, action.payload);
      yield put(getUsersByPackageSuccess(data));
    } catch (e) {
      yield put(getUsersByPackageFailure(e));
    }
  }

  function* requestGetLabCoinsCount(action) {
    try {
      const data = yield call(getLabCoinsCount, action.payload);
      yield put(getLabCoinsCountSuccess(data));
    } catch (e) {
      yield put(getLabCoinsCountFailure(e));
    }
  }

  function* requestGetLabsList(action) {
    try {
      const data = yield call(getCloudLabsList, action.payload);
      yield put(getLabsListSuccess(data));
    } catch (e) {
      yield put(getLabsListFailure(e));
    }
  }