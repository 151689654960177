import { USERS_STORIES_TYPES } from "../types";

//userStories Users List
export function requestGetUserStoriesUsersData(payload) {
  return {
    type: USERS_STORIES_TYPES.GET_USER_STORIES_USERS_DATA_REQUEST,
    payload,
  };
}

export function requestGetUserStoriesUsersDataSuccess(payload) {
  return {
    type: USERS_STORIES_TYPES.GET_USER_STORIES_USERS_DATA_SUCCESS,
    payload,
  };
}

export function requestGetUserStoriesUsersDataFailure(payload) {
  return {
    type: USERS_STORIES_TYPES.GET_USER_STORIES_USERS_DATA_FAILED,
    payload,
  };
}

export function requestGetTrackingDates(payload) {
  return {
    type: USERS_STORIES_TYPES.GET_TRACKING_DATES_REQUEST,
    payload,
  };
}

export function requestGetTrackingDatesSuccess(payload) {
  return {
    type: USERS_STORIES_TYPES.GET_TRACKING_DATES_SUCCESS,
    payload,
  };
}

export function requestGetTrackingDatesFailure(payload) {
  return {
    type: USERS_STORIES_TYPES.GET_TRACKING_DATES_FAILED,
    payload,
  };
}

export function requestUserJourney(payload) {
  return {
    type: USERS_STORIES_TYPES.GET_USER_JOURNEY_REQUEST,
    payload,
  };
}

export function requestUserJourneySuccess(payload) {
  return {
    type: USERS_STORIES_TYPES.GET_USER_JOURNEY_SUCCESS,
    payload,
  };
}

export function requestUserJourneyFailure(payload) {
  return {
    type: USERS_STORIES_TYPES.GET_USER_JOURNEY_FAILED,
    payload,
  };
}
