import { takeEvery, call, put } from "redux-saga/effects";
import {
    referralCountByIDFailure,
    referralCountByIDSuccess,
    referralCountFailure,
    referralCountSuccess,
    referralDistinctFailure,
    referralDistinctSuccess,
    } from "../actions/referral.action";
import { REFERRAL_TYPES } from "../types";
import {DistinctReferral, ReferralCount, ReferralCountById } from "../apis/referral.api"

export function* watchReferralRequests() {

  yield takeEvery(REFERRAL_TYPES.GET_REFERRAL_COUNT_REQUEST, RequestReferralCount);
  yield takeEvery(REFERRAL_TYPES.GET_REFERRAL_COUNT_ID_REQUEST, RequestReferralCountByID);
  yield takeEvery(REFERRAL_TYPES.GET_REFERRAL_DISTINCT_REQUEST, RequestDistinctReferral);
}

function* RequestReferralCount(action) {
  try {
    const data = yield call(ReferralCount, action.payload);
    yield put(referralCountSuccess(data));
  } catch (e) {
    yield put(referralCountFailure(e));
  }
}

function* RequestReferralCountByID(action) {
  try {
    const data = yield call(ReferralCountById, action.payload);
    yield put(referralCountByIDSuccess(data));
  } catch (e) {
    yield put(referralCountByIDFailure(e));
  }
}

function* RequestDistinctReferral(action) {
  try {
    const data = yield call(DistinctReferral, action.payload);
    yield put(referralDistinctSuccess(data));
  } catch (e) {
    yield put(referralDistinctFailure(e));
  }
}