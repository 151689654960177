import { USERS_TYPES } from "../types";

//getRegistered Users List
export function requestGetUsersData(payload) {
  return {
    type: USERS_TYPES.GET_USERS_DATA_REQUEST,
    payload,
  };
}

export function getUsersDataSuccess(payload) {
  return {
    type: USERS_TYPES.GET_USERS_DATA_SUCCESS,
    payload,
  };
}

export function getUsersDataFailure(payload) {
  return {
    type: USERS_TYPES.GET_USERS_DATA_FAILED,
    payload,
  };
}

//create User
export function requestCreateUser(payload) {
  return {
    type: USERS_TYPES.CREATE_USER_REQUEST,
    payload,
  };
}

export function createUserSuccess(payload) {
  return {
    type: USERS_TYPES.CREATE_USER_SUCCESS,
    payload,
  };
}

export function createUserFailure(payload) {
  return {
    type: USERS_TYPES.CREATE_USER_FAILED,
    payload,
  };
}


//uodate sales
export function requestUpdateSales(payload) {
  return {
    type: USERS_TYPES.UPDATE_SALES_REQUEST,
    payload,
  };
}

export function updateSalesSuccess(payload) {
  return {
    type: USERS_TYPES.UPDATE_SALES_SUCCESS,
    payload,
  };
}

export function updateSalesFailure(payload) {
  return {
    type: USERS_TYPES.UPDATE_SALES_FAILED,
    payload,
  };
}

//get User Payment Details
export function requestGetUserPaymetDetails(payload) {
  return {
    type: USERS_TYPES.GET_USER_PAYMENT_DETAILS_REQUEST,
    payload,
  };
}

export function requestGetUserPaymetDetailsSuccess(payload) {
  return {
    type: USERS_TYPES.GET_USER_PAYMENT_DETAILS_SUCCESS,
    payload,
  };
}

export function requestGetUserPaymetDetailsFailure(payload) {
  return {
    type: USERS_TYPES.GET_USER_PAYMENT_DETAILS_FAILED,
    payload,
  };
}


//getRegistered Users List
export function requestGetAllUsersData(payload) {
  return {
    type: USERS_TYPES.GET_ALL_USERS_DATA_REQUEST,
    payload,
  };
}

export function getAllUsersDataSuccess(payload) {
  return {
    type: USERS_TYPES.GET_ALL_USERS_DATA_SUCCESS,
    payload,
  };
}

export function getAllUsersDataFailure(payload) {
  return {
    type: USERS_TYPES.GET_ALL_USERS_DATA_FAILED,
    payload,
  };
}

export function requestGetUserData(payload) {
  return {
    type: USERS_TYPES.GET_USER_DATA_REQUEST,
    payload,
  };
}

export function getUserDataSuccess(payload) {
  return {
    type: USERS_TYPES.GET_USER_DATA_SUCCESS,
    payload,
  };
}

export function getUserDataFailure(payload) {
  return {
    type: USERS_TYPES.GET_USER_DATA_FAILED,
    payload,
  };
}