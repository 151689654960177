export const USERS_TYPES = {
    GET_USERS_DATA_REQUEST: "GET_USERS_DATA_REQUEST",
    GET_USERS_DATA_SUCCESS: "GET_USERS_DATA_SUCCESS",
    GET_USERS_DATA_FAILED: "GET_USERS_DATA_FAILED",

    GET_ALL_USERS_DATA_REQUEST: "GET_ALL_USERS_DATA_REQUEST",
    GET_ALL_USERS_DATA_SUCCESS: "GET_ALL_USERS_DATA_SUCCESS",
    GET_ALL_USERS_DATA_FAILED: "GET_ALL_USERS_DATA_FAILED",

    GET_USER_DATA_REQUEST: "GET_USER_DATA_REQUEST",
    GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",
    GET_USER_DATA_FAILED: "GET_USER_DATA_FAILED",

    CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_FAILED: "CREATE_USER_FAILED",

    UPDATE_SALES_REQUEST: "UPDATE_SALES_REQUEST",
    UPDATE_SALES_SUCCESS: "UPDATE_SALES_SUCCESS",
    UPDATE_SALES_FAILED: "UPDATE_SALES_FAILED",

    GET_USER_PAYMENT_DETAILS_REQUEST: "GET_USER_PAYMENT_DETAILS_REQUEST",
    GET_USER_PAYMENT_DETAILS_SUCCESS: "GET_USER_PAYMENT_DETAILS_SUCCESS",
    GET_USER_PAYMENT_DETAILS_FAILED: "GET_USER_PAYMENT_DETAILS_FAILED",
  };
  