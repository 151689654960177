import { API_END_POINTS } from "../endpoints";
import { ApiRequest } from "../../helpers";
import { Convert } from "../../helpers/convert.helper";
export function getCouponsData(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_COUPONS_DATA + `${query && "?" + query}`,
  }).then((response) => response.data);
}
