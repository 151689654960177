import { takeEvery, call, put } from "redux-saga/effects";
import {
  requestGetUserStoriesUsersDataFailure,
  requestGetUserStoriesUsersDataSuccess,
  requestGetTrackingDatesSuccess,
  requestGetTrackingDatesFailure,
  requestUserJourneySuccess,
  requestUserJourneyFailure,
} from "../actions";
import { USERS_STORIES_TYPES } from "../types";
import {
  getUserStoriesUsersData,
  getTrackingDates,
  getUserJourney,
} from "../apis/userStories.api";

export function* watchUserStoriesUsersRequests() {
  yield takeEvery(
    USERS_STORIES_TYPES.GET_USER_STORIES_USERS_DATA_REQUEST,
    requestGetUserStoriesUsersData
  );
  yield takeEvery(
    USERS_STORIES_TYPES.GET_TRACKING_DATES_REQUEST,
    requestGetTrackingDates
  );
  yield takeEvery(
    USERS_STORIES_TYPES.GET_USER_JOURNEY_REQUEST,
    requestUserJourney
  );
}

function* requestUserJourney(action) {
  try {
    const data = yield call(getUserJourney, action.payload);
    yield put(requestUserJourneySuccess(data));
  } catch (e) {
    yield put(requestUserJourneyFailure(e));
  }
}

function* requestGetTrackingDates(action) {
  try {
    const data = yield call(getTrackingDates, action.payload);
    yield put(requestGetTrackingDatesSuccess(data));
  } catch (e) {
    yield put(requestGetTrackingDatesFailure(e));
  }
}
function* requestGetUserStoriesUsersData(action) {
  try {
    const data = yield call(getUserStoriesUsersData, action.payload);
    yield put(requestGetUserStoriesUsersDataSuccess(data));
  } catch (e) {
    yield put(requestGetUserStoriesUsersDataFailure(e));
  }
}
