export const USERS_STORIES_TYPES = {
  GET_USER_STORIES_USERS_DATA_REQUEST: "GET_USER_STORIES_USERS_DATA_REQUEST",
  GET_USER_STORIES_USERS_DATA_SUCCESS:
    "GET_USER_STORIES_USERS_DATA_REQUEST_SUCCESS",
  GET_USER_STORIES_USERS_DATA_FAILED: "GET_USER_STORIES_USERS_DATA_FAILED",

  GET_TRACKING_DATES_REQUEST: "GET_TRACKING_DATES_REQUEST",
  GET_TRACKING_DATES_SUCCESS: "GET_TRACKING_DATES_SUCCESS",
  GET_TRACKING_DATES_FAILED: "GET_TRACKING_DATES_FAILED",

  GET_USER_JOURNEY_REQUEST: "GET_USER_JOURNEY_REQUEST",
  GET_USER_JOURNEY_SUCCESS: "GET_USER_JOURNEY_SUCCESS",
  GET_USER_JOURNEY_FAILED: "GET_USER_JOURNEY_FAILED",
};
