import { DASHBOARD_TYPES } from "../types";

//getRegistered Users
export function requestGetRegisteredUsers(payload) {
  return {
    type: DASHBOARD_TYPES.GET_REGISTERED_USERS_REQUEST,
    payload,
  };
}

export function getRegisteredUsersSuccess(payload) {
  return {
    type: DASHBOARD_TYPES.GET_REGISTERED_USERS_SUCCESS,
    payload,
  };
}

export function getRegisteredUsersFailure(payload) {
  return {
    type: DASHBOARD_TYPES.GET_REGISTERED_USERS_FAILED,
    payload,
  };
}

//getUsers By Pamyment Deatsils
export function requestGetUsersByPaymentDetails(payload) {
  return {
    type: DASHBOARD_TYPES.GET_USERS_BY_PAYMENT_DETAILS_REQUEST,
    payload,
  };
}

export function getUsersByPaymentDetailsSuccess(payload) {
  return {
    type: DASHBOARD_TYPES.GET_USERS_BY_PAYMENT_DETAILS_SUCCESS,
    payload,
  };
}

export function getUsersByPaymentDetailsFailure(payload) {
  return {
    type: DASHBOARD_TYPES.GET_USERS_BY_PAYMENT_DETAILS_FAILED,
    payload,
  };
}

//getUsers By Package Deatsils
export function requestGetUsersByPackage(payload) {
  return {
    type: DASHBOARD_TYPES.GET_USERS_BY_PACKAGE_REQUEST,
    payload,
  };
}

export function getUsersByPackageSuccess(payload) {
  return {
    type: DASHBOARD_TYPES.GET_USERS_BY_PACKAGE_SUCCESS,
    payload,
  };
}

export function getUsersByPackageFailure(payload) {
  return {
    type: DASHBOARD_TYPES.GET_USERS_BY_PACKAGE_FAILED,
    payload,
  };
}

//get total Cretdit Count
export function requestGetLabCoinsCount(payload) {
  return {
    type: DASHBOARD_TYPES.GET_LAB_CREDITS_COUNT_REQUEST,
    payload,
  };
}

export function getLabCoinsCountSuccess(payload) {
  return {
    type: DASHBOARD_TYPES.GET_LAB_CREDITS_COUNT_SUCCESS,
    payload,
  };
}

export function getLabCoinsCountFailure(payload) {
  return {
    type: DASHBOARD_TYPES.GET_LAB_CREDITS_COUNT_FAILED,
    payload,
  };
}

//get CloudLabs List Consumed 
export function requestGetLabsList(payload) {
  return {
    type: DASHBOARD_TYPES.GET_CLOUD_LABS_LIST_REQUEST,
    payload,
  };
}

export function getLabsListSuccess(payload) {
  return {
    type: DASHBOARD_TYPES.GET_CLOUD_LABS_LIST_SUCCESS,
    payload,
  };
}

export function getLabsListFailure(payload) {
  return {
    type: DASHBOARD_TYPES.GET_CLOUD_LABS_LIST_FAILED,
    payload,
  };
}