import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";
import { darkTheme, GlobalStyles, lightTheme } from "../theme";
import { useRoutes } from "react-router-dom";
import { signInUserSuccess } from "../redux/actions";
import { LStorage } from "../helpers";
import routes from "../routes";

function App(props) {
  const userData = LStorage.getUserData();
  const routing = useRoutes(routes(Boolean(userData?.accessToken)));
  // const routing = useRoutes(routes(true));

  useEffect(() => {
    if (userData) props.signInUserSuccess(userData);
  }, []);

  return (
    <div>
      <ThemeProvider
        theme={props?.theme?.mode === "light" ? lightTheme : darkTheme}
      >
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    theme: state?.theme,
    user: state?.user,
  };
}

export default connect(mapStateToProps, { signInUserSuccess })(App);
