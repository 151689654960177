import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const Loader = styled.div`
  text-align: center;
  padding: 50px 0px;
`;

export const UserTrackingBox = styled.div`
  padding: 30px 15px 0px 15px;
`;

export const MoreBtn = styled.div`
  text-align: center;
  padding: 20px 0px;
  background: #eeeeff;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #d0c7c7;
  cursor: pointer;
`;

export const UserTrackingHeaderBox = styled.div`
  padding: 30px 15px 20px 15px;
  display: flex;
`;
export const UserTrackingActivityBox = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
`;
export const ActivityBox = styled.div`
  padding: 7px 10px;
  border: 1px solid black;
  border-radius: 8px;
  min-width: 200px;
  text-align: center;
  margin-right: 40px;
`;

export const UserBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const ContentBox = styled.div`
  padding: 20px 20px;
  min-width: 280px;
  text-align: center;
  border: 1px solid black;
  margin-right: 20px;
  border-radius: 8px;
  margin-right: 100px;
  margin-bottom: 100px;
`;

export const ContentText = styled.div`
  font-weight: 500;
`;

export const OpenPage = styled.div`
  font-weight: 500;
  color: rgb(123, 104, 238);
  cursor: pointer;
`;
