export const GLOBAL_TYPES = {
    GET_COUNTRY_LIST_REQUEST: "GET_COUNTRY_LIST_REQUEST",
    GET_COUNTRY_LIST_SUCCESS: "GET_COUNTRY_LIST_SUCCESS",
    GET_COUNTRY_LIST_FAILED: "GET_COUNTRY_LIST_FAILED",

    GET_PACKAGE_LIST_REQUEST: "GET_PACKAGE_LIST_REQUEST",
    GET_PACKAGE_LIST_SUCCESS: "GET_PACKAGE_LIST_SUCCESS",
    GET_PACKAGE_LIST_FAILED: "GET_PACKAGE_LIST_FAILED",

    GET_COUPONS_LIST_REQUEST:"GET_COUPONS_LIST_REQUEST",
    GET_COUPONS_LIST_SUCCESS:"GET_COUPONS_LIST_SUCCESS",
    GET_COUPONS_LIST_FAILED:"GET_COUPONS_LIST_FAILED"
  };
  