import React, { useState, useEffect } from "react";
import { Validate } from "../../../helpers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Modal,
  Input,
  Button,
  Dropdown,
  TextArea,
  Switch,
  Checkbox,
  Calender,
} from "../../../theme/components";
import moment from "moment";

const formData = {
  firstName: "",
  lastName: "",
  email: "",
  packageId: "",
  amountPaid: "",
  amountDue: "",
  notes: "",
  phoneNumber: "",
  expiryDate: "",
};

function AddEditUser(props) {
  const [form, setForm] = useState(formData);
  const {
    show = false,
    onClose = () => { },
    editId = "",
    packageDropdownList = [],
    isCreateUpdateUserLoading,
  } = props;
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isReminderEnabled, setIsReminderEnabled] = useState(true);
  const [isNotifyEmailEnabled, setIsNotifyEmailEnabled] = useState(true);
  const [isNotifySmsEnabled, setIsNotifySmsEnabled] = useState(true);
  const [selectedDropdown, setSelectedDropdown] = useState([]);

  const handleChange = (e) => {
    //Caliculate the due amount on change amountPaid Based On OnChange
    if (e.target.name === "amountPaid") {
      if (!form?.packageId) {
        alert("plz select package");
        return;
      }
      const packageDetails = packageDropdownList?.filter(
        (packageId) => packageId?.id === form?.packageId
      );
      const dueEmount = packageDetails[0]?.price - e.target.value;
      //Check User Entered Value Should Be Lower then he Package Price
      if (e.target.value > packageDetails[0]?.price) {
        alert("Plz Enter Valid Amount");
        return;
      }
      setForm({
        ...form,
        amountDue: dueEmount > 0 ? dueEmount : 0, //Prevent Negative Values
        [e.target.name]:
          e.target.value.trim() && parseInt(e.target.value.trim()),
      });
    } else {
      setForm({
        ...form,
        [e.target.name]:
          e.target.name === "notes" ? e.target.value : e.target.value.trim(),
      });
    }
  };

  // ****************************
  // * Validation Hanlder Start *
  // ****************************
  const handleValidation = () => {
    let errorStructure = {
      errorEmail: "",
      errorFirstName: "",
      errorLastName: "",
      isValidated: false,
      errorPackageId: "",
      errorAmountPaid: "",
      errornotes: "",
      errorPhoneNumber: "",
      errorExpiryDate: "",
    };

    if (!isFormSubmit) return errorStructure;

    if (!form?.email) {
      errorStructure = { ...errorStructure, errorEmail: "Please enter email" };
    } else if (!Validate.email(form?.email)) {
      errorStructure = {
        ...errorStructure,
        errorEmail: "Please enter valid email",
      };
    }

    if (!form?.firstName) {
      errorStructure = {
        ...errorStructure,
        errorFirstName: "Please enter first name",
      };
    } else if (!Validate.minLength(form?.firstName, 2)) {
      errorStructure = {
        ...errorStructure,
        errorFirstName: "Please enter valid first name",
      };
    }

    if (!form?.lastName) {
      errorStructure = {
        ...errorStructure,
        errorLastName: "Please enter last name",
      };
    } else if (!Validate.minLength(form?.lastName, 2)) {
      errorStructure = {
        ...errorStructure,
        errorLastName: "Please enter valid last name",
      };
    }

    if (!form?.packageId) {
      errorStructure = {
        ...errorStructure,
        errorPackageId: "Please select package",
      };
    } else if (!form?.packageId) {
      errorStructure = {
        ...errorStructure,
        errorPackageId: "Please select package",
      };
    }

    if (!form?.amountPaid) {
      errorStructure = {
        ...errorStructure,
        errorAmountPaid: "Please enter amount paid",
      };
    } else if (!form?.amountPaid) {
      errorStructure = {
        ...errorStructure,
        errorAmountPaid: "Please enter amount paid",
      };
    }
    if (!form?.expiryDate) {
      errorStructure = {
        ...errorStructure,
        errorExpiryDate: "Please enter expire date",
      };
    }
    if (
      !errorStructure?.errorEmail &&
      !errorStructure?.errorFirstName &&
      !errorStructure?.errorLastName &&
      !errorStructure?.errorExpiryDate &&
      !errorStructure.errorPackageId &&
      !errorStructure.errorAmountPaid &&
      !errorStructure.errornotes &&
      !errorStructure.errorPhoneNumber
    ) {
      errorStructure = {
        ...errorStructure,
        isValidated: true,
      };
    }

    if (!form?.notes) {
      errorStructure = { ...errorStructure, errornotes: "Please enter notes" };
    } else if (!Validate.minLength(form?.notes, 2)) {
      errorStructure = {
        ...errorStructure,
        errornotes: "Please enter valid notes",
      };
    }
    if (!form?.phoneNumber) {
      errorStructure = {
        ...errorStructure,
        errorPhoneNumber: "Please enter Phone Number",
      };
    } else if (!Validate.minLength(form?.phoneNumber, 12)) {
      errorStructure = {
        ...errorStructure,
        errorPhoneNumber: "Please enter valid Phone Number",
      };
    }

    return errorStructure;
  };
  // **************************
  // * Validation Hanlder End *
  // **************************

  // Destructure Errors
  const {
    errorEmail,
    errorFirstName,
    errorLastName,
    errorPackageId,
    errorAmountPaid,
    errornotes,
    errorPhoneNumber,
    errorExpiryDate,
  } = handleValidation();

  useEffect(() => {
    // If The Mode Is Edit SettingUp The Detault Values
    if (editId) {
      let selectedDropdown;
      packageDropdownList?.map((option) => {
        if (option?.id === editId?.packageId) {
          selectedDropdown = [option];
        }
      });
      setSelectedDropdown(selectedDropdown);
      setTimeout(() => {
        setForm({
          ...form,
          firstName:
            editId?.users &&
            editId?.users.length > 0 &&
            editId?.users[0]?.firstName,
          lastName:
            editId?.users &&
            editId?.users.length > 0 &&
            editId?.users[0]?.lastName,
          email:
            editId?.users &&
            editId?.users.length > 0 &&
            editId?.users[0]?.email,
          amountDue: editId?.amountDue,
          amountPaid: editId?.amountPaid,
          packageId: editId?.packageId,
          notes: editId?.notes,
          expiryDate:
            editId?.expiryDate &&
            moment.unix(editId?.expiryDate).format("MM/DD/YYYY"),
        });
      }, 1000);
    }
  }, []);

  // On Create Update Users Details 1st Step
  const onSubmit = () => {
    setIsFormSubmit(Math.random());
  };

  //On Change Form Submit 2nd Step
  useEffect(() => {
    if (isFormSubmit) {
      handleSubmit();
    }
  }, [isFormSubmit]);

  //User Update And Creation 3rd Step
  const handleSubmit = () => {
    const { isValidated } = handleValidation();
    if (!isValidated) return;
    const params = {
      ...form,
      ...(isReminderEnabled && {
        reminder_enabled: isReminderEnabled,
        notify: {
          email: isNotifyEmailEnabled,
          sms: isNotifySmsEnabled,
        },
      }),
    };
    if (editId) {
      props.updateSales({ id: editId?.id, ...params });
    } else {
      props.createUser(params);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onClose={onClose}
        title={<h4>{editId ? "Edit" : "Create New"} User</h4>}
      >
        <label>Select Package</label>
        <Dropdown
          options={packageDropdownList}
          labelField="name"
          valueField="id"
          values={selectedDropdown}
          disabled={editId}
          style={{ maxWidth: "98%" }}
          className="mb-2 mt-1"
          placeholder="Select Package"
          isError={Boolean(errorPackageId)}
          errorMessage={errorPackageId}
          onChange={(selectedValue) => {
            setForm({
              ...form,
              packageId: selectedValue[0]?.id,
            });
          }}
        />
        <Input
          className="mb-2"
          label="First Name"
          name="firstName"
          placeholder="First name"
          maxWidth="100%"
          onChange={handleChange}
          disabled={editId}
          value={form.firstName}
          isError={Boolean(errorFirstName)}
          errorMessage={errorFirstName}
        />
        <Input
          className="mb-2"
          label="Last Name"
          name="lastName"
          placeholder="Last name"
          maxWidth="100%"
          disabled={editId}
          onChange={handleChange}
          value={form.lastName}
          isError={Boolean(errorLastName)}
          errorMessage={errorLastName}
        />
        {!editId && (
          <Input
            className="mb-2"
            label="Phone (country code is required)"
            name="phoneNumber"
            placeholder="+91 0123456789"
            maxWidth="100%"
            onChange={handleChange}
            value={form.phoneNumber}
            isError={Boolean(errorPhoneNumber)}
            errorMessage={errorPhoneNumber}
          />
        )}
        <Input
          className="mb-2"
          label="Email"
          name="email"
          placeholder="Email"
          maxWidth="100%"
          onChange={handleChange}
          disabled={editId}
          value={form.email}
          isError={Boolean(errorEmail)}
          errorMessage={errorEmail}
        />
        <Calender
          name="expiryDate"
          classNames={"mb-2"}
          label="select expiry date"
          disabled={editId}
          selected={form?.expiryDate ? new Date(moment.unix(form?.expiryDate).format("MM/DD/YYYY")) :  new Date()}
          onChange={(date) =>
            setForm({
              ...form,
              expiryDate: moment(date).unix(),
            })
          }
          isError={Boolean(errorExpiryDate)}
          errorMessage={errorExpiryDate}
        />
        {!isChecked && (
          <>
            <Input
              className="mb-2"
              label="Paid Amount"
              name="amountPaid"
              placeholder="Enter paid amount"
              maxWidth="100%"
              onChange={handleChange}
              value={form.amountPaid}
              // disabled={!form.packageId}
              isError={Boolean(errorAmountPaid)}
              errorMessage={errorAmountPaid}
            />
            <Input
              className="mb-2"
              label="Due"
              name="amountDue"
              placeholder="Due amount"
              maxWidth="100%"
              disabled
              onChange={handleChange}
              value={form.amountDue}
            />
          </>
        )}
        <TextArea
          className="mb-2"
          label="Please enter the deadline of the pending payment"
          name="notes"
          placeholder="Notes"
          maxWidth="100%"
          onChange={handleChange}
          value={form?.notes}
          isError={Boolean(errornotes)}
          errorMessage={errornotes}
        />
        {editId && editId?.paymentLink && (
          <div className="row">
            <div className="col col-lg-6">
              <Input disabled label="Payment Id" value={editId.paymentId} />
              <Button
                className="mt-2"
                style={{ maxHeight: "35px" }}
                small
                outline
                onClick={() => props.fetchUserPaymentDetails(editId)}
              >
                Get Payment Details
              </Button>
            </div>
            <div className="col-md-6">
              <Input disabled label="Payment Link" value={editId.paymentLink} />
            </div>
          </div>
        )}
        {props?.userPaymentDetails && (
          <div className="row px-5">
            <div className="col col-lg-6 my-2">
              <span>
                Amount Paid :{" "}
                {props?.userPaymentDetails?.amountPaid === 0
                  ? " N/A"
                  : props?.userPaymentDetails?.amountPaid}
              </span>
            </div>
            <div className="col-md-6 my-2">
              <span>
                Expire By :{" "}
                {props?.userPaymentDetails?.expireBy === 0
                  ? " N/A"
                  : props?.userPaymentDetails?.expireBy}
              </span>
            </div>
            <div className="col col-lg-6 my-2">
              <span>
                Expired At :{" "}
                {props?.userPaymentDetails?.expiredAt === 0
                  ? " N/A"
                  : props?.userPaymentDetails?.expiredAt}
              </span>
            </div>
            <div className="col-md-6 my-2">
              <span>
                Created At :{" "}
                {props?.userPaymentDetails?.createdAt
                  ? moment
                    .unix(props?.userPaymentDetails?.createdAt)
                    .format("DD-MM-YYYY , HH:mm A")
                  : " N/A"}
              </span>
            </div>
          </div>
        )}
        {!editId && (
          <>
            <label className="mt-2" style={{ fontSize: "0.9rem" }}>
              Send update to user regarding payment
            </label>
            <Switch
              key={"reminder_enabled"}
              name="reminder_enabled"
              small
              checked={isReminderEnabled}
              onChange={() => setIsReminderEnabled(!isReminderEnabled)}
            />
          </>
        )}
        {!editId && isReminderEnabled && (
          <>
            <div className="d-flex mt-1">
              <label className="mx-2" style={{ fontSize: "0.9rem" }}>
                Email
              </label>
              <Checkbox
                key={"reminderEmail"}
                name="reminderEmail"
                small
                checked={isNotifyEmailEnabled}
                onChange={() => setIsNotifyEmailEnabled(!isNotifyEmailEnabled)}
              />
              <label className="mx-2" style={{ fontSize: "0.9rem" }}>
                SMS
              </label>
              <Checkbox
                key={"reminderSms"}
                name="reminderSms"
                small
                checked={isNotifySmsEnabled}
                onChange={() => setIsNotifySmsEnabled(!isNotifySmsEnabled)}
              />
            </div>
          </>
        )}
        <Button
          className="mt-2"
          small
          disabled={isCreateUpdateUserLoading}
          onClick={onSubmit}
        >
          {isCreateUpdateUserLoading
            ? "Loading..."
            : editId
              ? "Save"
              : "Create User"}
        </Button>
      </Modal>
    </div>
  );
}

export default AddEditUser;
