import React from "react";
import { connect } from "react-redux";
import { signOutUserSuccess } from "../../../redux/actions";
import { Button } from "../../../theme/components";
import Header from "./header";
import Sidebar from "./sidebar";
import {
  MainComponentBox,
  ContentBox,
  ContentChildrenBox,
} from "./main.styled";

function MainLayout(props) {
  const handleLogout = () => {
    props.signOutUserSuccess();
  };

  return (
    <MainComponentBox>
      <Sidebar />
      <ContentBox>
        <Header />
        {/* <Button onClick={handleLogout}>Signout</Button> */}
        <ContentChildrenBox>{props?.children}</ContentChildrenBox>
      </ContentBox>
    </MainComponentBox>
  );
}

function mapStateToProps(state) {
  return {
    user: state?.user,
  };
}

export default connect(mapStateToProps, { signOutUserSuccess })(MainLayout);
