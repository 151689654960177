import React, { useEffect, useState } from "react";
import {
  UserBox,
  ContentBox,
  RowContentBox,
  ListContentBox,
  Loader,
  MoreBtn,
} from "./userStories.styled";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { requestGetTrackingDates } from "../../redux/actions";
import { Constant } from "../../helpers";
import { USERS_STORIES_TYPES } from "../../redux/types";
import { Button } from "../../theme/components";
import { useNavigate } from "react-router-dom";

function UserStories(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userStoriesData, setUserStoriesData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(1);
  let params = new URLSearchParams(document.location.search);
  let userId = params.get("userId");

  useEffect(() => {
    const requestUsersData = EventBus.on(
      USERS_STORIES_TYPES.GET_TRACKING_DATES_REQUEST,
      () => {
        setIsLoading(true);
      }
    );
    const usersDataSuccess = EventBus.on(
      USERS_STORIES_TYPES.GET_TRACKING_DATES_SUCCESS,
      (res) => {
        console.log("res", res?.data?.items);
        setUserStoriesData((prev) => [...prev, ...res?.data?.items]);
        // setUserStoriesData([...userStoriesData, ...(res?.data?.items || [])]);
        setPaginationData(res?.data || {});
        setIsLoading(false);
      }
    );
    const usersDataFailure = EventBus.on(
      USERS_STORIES_TYPES.GET_TRACKING_DATES_FAILED,
      () => {
        setIsLoading(false);
      }
    );

    return () => {
      requestUsersData();
      usersDataSuccess();
      usersDataFailure();
    };
  }, []);

  useEffect(() => {
    props.requestGetTrackingDates({
      userId: userId,
      page: page,
    });
  }, [page]);

  return (
    <div>
      <UserBox>
        {userStoriesData &&
          userStoriesData.length > 0 &&
          userStoriesData.map((data, index) => {
            return (
              <ContentBox key={index}>
                <Button primary small>
                  Date: {data?.journeyDate}
                </Button>
                <RowContentBox>
                  {Object.entries(Constant.modules()).map(
                    ([key, value], index) => {
                      return (
                        <ListContentBox
                          key={index}
                          onClick={() => {
                            navigate(
                              `/user-tracking?journeyDate=${data?.journeyDate}&userId=${userId}&type=${value}`
                            );
                          }}
                        >
                          {value}
                        </ListContentBox>
                      );
                    }
                  )}
                </RowContentBox>
              </ContentBox>
            );
          })}
        {console.log("paginationData", paginationData?.totalPages)}
        {isLoading ? (
          <Loader>Fetching...</Loader>
        ) : (
          <>
            {userStoriesData.length <= 0 && <div>No data found</div>}
            {page < paginationData?.totalPages && (
              <MoreBtn onClick={() => setPage(page + 1)}>More</MoreBtn>
            )}
          </>
        )}
      </UserBox>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps, {
  requestGetTrackingDates,
})(UserStories);
