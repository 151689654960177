import React, { useEffect, useState } from "react";
import {
  UserBox,
  ContentBox,
  ContentText,
  OpenPage,
  UserTrackingBox,
  UserTrackingHeaderBox,
  ActivityBox,
  UserTrackingActivityBox,
  Loader,
  MoreBtn,
} from "./userTracking.styled";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { requestUserJourney } from "../../redux/actions";
import { Constant } from "../../helpers";
import { USERS_STORIES_TYPES } from "../../redux/types";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Modal } from "../../theme/components";
import moment from "moment";

function UserTracking(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [userJourneysData, setUserJourneysData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  let params = new URLSearchParams(document.location.search);
  let userId = params.get("userId");
  let journeyDate = params.get("journeyDate");
  let type = params.get("type");
  let count = 0;

  useEffect(() => {
    const requestUsersJourneyData = EventBus.on(
      USERS_STORIES_TYPES.GET_USER_JOURNEY_REQUEST,
      () => {
        setIsLoading(true);
      }
    );
    const userJourneySuccess = EventBus.on(
      USERS_STORIES_TYPES.GET_USER_JOURNEY_SUCCESS,
      (res) => {
        setUserJourneysData((prev) => [...prev, ...res?.data?.items]);
        setPaginationData(res?.data || {});
        setIsLoading(false);
      }
    );
    const userJourneyFailure = EventBus.on(
      USERS_STORIES_TYPES.GET_USER_JOURNEY_FAILED,
      () => {
        setIsLoading(false);
      }
    );

    return () => {
      requestUsersJourneyData();
      userJourneySuccess();
      userJourneyFailure();
    };
  }, []);

  useEffect(() => {
    props.requestUserJourney({
      userId: userId,
      journeyStartDate: journeyDate,
      module: type,
      page: page,
    });
  }, [page]);

  return (
    <div>
      <UserTrackingBox>
        <UserTrackingHeaderBox>
          <ActivityBox>{type}</ActivityBox>
          <Button primary small>
            Date: {journeyDate}
          </Button>
        </UserTrackingHeaderBox>
        {/* <UserTrackingActivityBox>
          <Button primary small>
            Export Activity
          </Button>
        </UserTrackingActivityBox> */}
        <UserBox>
          {userJourneysData &&
            userJourneysData.length > 0 &&
            userJourneysData.map((data, index) => {
              console.log("data", data.events);
              return (
                <ContentBox key={index}>
                  <ContentText>
                    Start Time:{" "}
                    {data?.journeyStartTime
                      ? moment(data?.journeyStartTime).format("hh:mm:ss a")
                      : ""}
                  </ContentText>
                  <ContentText>
                    End Time:{" "}
                    {data?.journeyEndTime
                      ? moment(data?.journeyEndTime).format("hh:mm:ss a")
                      : ""}
                  </ContentText>
                  <ContentText>
                    No. of triggered events:{" "}
                    {data?.events ? data?.events.length : 0}
                  </ContentText>

                  <OpenPage onClick={() => setShow(data.events)}>
                    Open Page
                  </OpenPage>
                </ContentBox>
              );
            })}
        </UserBox>
      </UserTrackingBox>

      {isLoading ? (
        <Loader>Fetching...</Loader>
      ) : (
        <>
          {userJourneysData.length <= 0 && (
            <div style={{ padding: "30px" }}>No data found</div>
          )}
          {page < paginationData?.totalPages && (
            <MoreBtn onClick={() => setPage(page + 1)}>More</MoreBtn>
          )}
        </>
      )}
      <Modal
        show={show}
        onClose={() => setShow(false)}
        title={<h4>Events Detail</h4>}
      >
        {show &&
          show.length > 0 &&
          show.map((data, index) => {
            return (
              <div key={index}>
                {data?.eventData &&
                  data?.eventData.length > 0 &&
                  data?.eventData.map((eData, eIndex) => {
                    ++count;
                    return (
                      <div key={eIndex}>
                        <h4>Serial No. {count}</h4>
                        <ContentText>Event Id: {eData?.id}</ContentText>
                        <ContentText>Event Action: {eData?.action}</ContentText>
                        <ContentText>Element: {eData?.element}</ContentText>
                        <ContentText>
                          Element Id: {eData?.elementId}
                        </ContentText>
                        <ContentText>Page: {eData?.page}</ContentText>
                        <br />
                        <br />
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps, {
  requestUserJourney,
})(UserTracking);
