import { legacy_createStore as createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { eventBusMiddleware } from "../middlewares";
import { composeWithDevTools } from "@redux-devtools/extension";
import rootReducer from "../reducers";
import rootSaga from "../sagas";

const middlewares = [eventBusMiddleware];

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);

const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export { store };
