import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { useEffect, useRef, useState } from "react";

/* Actions */
import {
  referralCountByIDFailure,
  referralCountByIDSuccess,
  referralCountFailure,
  referralCountSuccess,
  referralDistinctFailure,
  referralDistinctSuccess,
  requestReferralCount,
  requestReferralCountByID,
  requestReferralDistinct
  } from "../../redux/actions/referral.action";

/* Images */
import TotalItemsImg from "../../assets/icons/totalItems.svg";

/* Components */
import { Dropdown, Card } from "../../theme/components";

/* Styled */
import { ReferalsBox, Ticket, TicketBox } from "./referals.styled"
import { REFERRAL_TYPES } from "../../redux/types";


const initialState = {
    "totalCount": 0,
    "countLastHour": 0,
    "countLast24Hours": 0
}

function Referral(props) {

  /* Effect */
  const [referralCount, setReferralCount] = useState(0);
  const [distinctReferral, setDistinctReferral] = useState({referredByValues: []});
  const [selectedValue, setSelectedValue] = useState("");
  const initialCount = useRef(0)

  useEffect(() => {
    const referralCountLoading = EventBus.on(REFERRAL_TYPES.GET_REFERRAL_COUNT_REQUEST, () => {})
    const referralCountSuccess = EventBus.on(REFERRAL_TYPES.GET_REFERRAL_COUNT_SUCCESS, (data) => {
      setReferralCount(data.totalCount);
      initialCount.current = data.totalCount;
    })
    const referralCountFailure = EventBus.on(REFERRAL_TYPES.GET_REFERRAL_COUNT_FAILURE, () => {})

    const referralCountByIDSuccess = EventBus.on(REFERRAL_TYPES.GET_REFERRAL_COUNT_ID_SUCCESS, (data) => {
      setReferralCount(data.totalCount);
    })
    
    const referralDistinctLoading = EventBus.on(REFERRAL_TYPES.GET_REFERRAL_DISTINCT_REQUEST, () => {})
    const referralDistinctSuccess = EventBus.on(REFERRAL_TYPES.GET_REFERRAL_DISTINCT_SUCCESS, (data) => {
        const filteredData = data.referredByValues.filter((item) => item !== null);
        filteredData.push("All")
        setDistinctReferral({ referredByValues: filteredData,  });
    })
    const referralDistinctFailure = EventBus.on(REFERRAL_TYPES.GET_REFERRAL_DISTINCT_FAILURE, () => {})

    props.requestReferralCount();
    props.requestReferralDistinct();

    return () => {
      referralCountLoading();
      referralCountSuccess();
      referralCountFailure();

      referralCountByIDSuccess();

      referralDistinctLoading();
      referralDistinctSuccess();
      referralDistinctFailure();

    };
  }, [])

  useEffect(() => {
    if(selectedValue === "") return;
    else if(selectedValue === "All") setReferralCount(initialCount.current);


  }, [selectedValue])

  return (
    <ReferalsBox>
        <Card>
          <TicketBox>
          <Ticket color="lightBlue1">
            <div className="left-ticket">
              <img src={TotalItemsImg} alt="Pending" width="54" />
            </div>
            <div className="d-flex flex-column w-100" >
              <p>Total Referrals </p>
              <div className="d-flex justify-content-between">
                <h3>{referralCount}</h3>
                <Dropdown options={distinctReferral.referredByValues.map((opt) => ({ label: opt, value: opt }))}
                  style={{ marginLeft: "10px", backgroundColor: "white",minWidth: '150px' }}
                  placeholder="Select Referral"
                  searchable={false}
                  onChange={(selectedValue) => {
                    setSelectedValue(selectedValue[0].value);
                    if(selectedValue[0].label === "All")
                        props.requestReferralCount()
                    else props.requestReferralCountByID({ referredBy: selectedValue[0]?.value });
                  }}
                />
              </div>
            </div>
          </Ticket>
          </TicketBox>
        </Card>
    </ReferalsBox>
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  referralCountByIDFailure,
  referralCountByIDSuccess,
  referralCountFailure,
  referralCountSuccess,
  referralDistinctFailure,
  referralDistinctSuccess,
  requestReferralCount,
  requestReferralCountByID,
  requestReferralDistinct
})(Referral);