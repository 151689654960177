import { takeEvery, call, put } from "redux-saga/effects";
import {
  signInUserSuccess,
  signInUserFailure,
  otpVerifySuccess,
  otpVerifyFailure,
  getUsersFailure,
  getUsersSuccess,
  getNotificationsSuccess,
  getNotificationsFailure,
  updateNotificationsSuccess,
  updatetNotificationsFailure
} from "../actions";
import { USER_TYPES } from "../types";
import { signInUser, verifyOtp , getUsers, getNotifications, updateNotifications} from "../apis";

export function* watchUserRequests() {
  yield takeEvery(USER_TYPES.GET_USERS_REQUEST, requestGetUsers);
  yield takeEvery(USER_TYPES.SIGN_IN_REQUEST, requestSignInUser);
  yield takeEvery(USER_TYPES.OTP_VERIFY_REQUEST, requestOtpVerify);
  yield takeEvery(USER_TYPES.GET_USER_NOTIFICATIONS, requestGetNotifications);
  yield takeEvery(USER_TYPES.UPDATE_USER_NOTIFICATIONS, requestUpdateNotifications);
}

function* requestSignInUser(action) {
  try {
    const data = yield call(signInUser, action.payload);
    yield put(signInUserSuccess(data));
  } catch (e) {
    yield put(signInUserFailure(e));
  }
}

function* requestOtpVerify(action) {
  try {
    const data = yield call(verifyOtp, action.payload);
    yield put(otpVerifySuccess(data));
  } catch (e) {
    yield put(otpVerifyFailure(e));
  }
}

function* requestGetUsers(action) {
  try {
    const data = yield call(getUsers, action.payload);
    yield put(getUsersSuccess(data));
  } catch (e) {
    yield put(getUsersFailure(e));
  }
}

function* requestGetNotifications(action) {
  try {
    const data = yield call(getNotifications, action.payload);
    yield put(getNotificationsSuccess(data));
  } catch (e) {
    yield put(getNotificationsFailure(e));
  }
}

function* requestUpdateNotifications(action) {
  try {
    const data = yield call(updateNotifications, action.payload);
    yield put(updateNotificationsSuccess(data));
  } catch (e) {
    yield put(updatetNotificationsFailure(e));
  }
}