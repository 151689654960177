import React, { useEffect, useState } from "react";
import { UserBox, SearchBox, FilterOptionsBox } from "./user.styled";
import {
  StatusChip,
  Table,
  Card,
  ContentHeader,
  Dropdown,
  Input,
  Tooltip,
} from "../../theme/components";
import AddEditUser from "./addEditUser";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import RemoveUser from "./removeUser";
import { ReactComponent as EditImg } from "../../assets/icons/edit.svg";
import { ReactComponent as BookImg } from "../../assets/icons/book.svg";
import {
  requestGetUsersData,
  requestCreateUser,
  requestGetPackageList,
  requestUpdateSales,
  requestGetUserPaymetDetails,
  requestGetAllUsersData,
  requestGetUserData,
} from "../../redux/actions";
import { useNavigate } from "react-router-dom";

import { CiMenuKebab } from "react-icons/ci";
import { USERS_TYPES, GLOBAL_TYPES } from "../../redux/types";
import { useLocation } from "react-router-dom";

// to get Query Params Use this function ex:- {baseUrl}?{key}={value}
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Users(props) {
  let query = useQuery();
  const [show, setShow] = useState(false);
  const [activeTooltipId, setActiveTooltipId] = useState("");
  const [removeId, setRemoveId] = useState("");
  const [editId, setEditId] = useState("");
  const [search, setSearch] = useState("");
  const [isUserDataFetching, setIsUserDataFetching] = useState(true);
  const [sortby, setSortBy] = useState("Sales Dashboard");
  const [usersData, setUsersData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [packageDropdownList, setPackageDropdownList] = useState([]);
  const [isCreateUpdateUserLoading, setIsCreateUpdateUserLoading] =
    useState(false);
  const [userPaymentDetails, setUserPaymentDetails] = useState(null);
  const navigate = useNavigate();
  const [isSameUsersData, setIsSameUsersData] = useState("Sales Dashboard");

  const handleOnClose = () => {
    setShow(false);
  };

  const handleOnCloseRemoveMember = () => {
    setRemoveId("");
  };

  //Columns Declaration For The User Table
  const columns = [
    {
      key: "firstName",
      title: "Name",
      render: (data, type, row) => {
        if (type?.users) {
          return type?.users[0]?.firstName + " " + type?.users[0]?.lastName;
        } else {
          return type ? type?.firstName + " " + type?.lastName : "N/A";
        }
      },
    },
    {
      key: "email",
      title: "Email",
      render: (data, type, row) => {
        if (type?.users) {
          return type?.users[0]?.email;
        } else {
          return type ? type?.email : "N/A";
        }
      },
    },
    {
      key: "creditsAvailable",
      title: "Credits",
      render: (data, type, row) => {
        if (type?.users) {
          return type?.users[0]?.creditsAvailable;
        } else {
          return type && type?.creditsAvailable
            ? type?.creditsAvailable
            : "N/A";
        }
      },
    },
    {
      ...(sortby === "Sales Dashboard" && {
        key: "packageId",
        title: "Package",
        render: (data, type, row) => {
          const packageDetails = packageDropdownList?.filter(
            (packageId) => packageId?.id === type?.packageId
          );
          return packageDetails[0]?.name;
        },
      }),
    },
    {
      key: "status",
      title: "Profile",
      render: (data, type, row) => {
        if (type?.users) {
          return type?.users[0]?.profileComplete ? (
            <StatusChip title="Complete" />
          ) : (
            <StatusChip secondary title="Incomplete" />
          );
        } else {
          console.log(type);
          return type && type?.status?.status ? (
            <StatusChip title={type?.status?.status} />
          ) : (
            "N/A"
          );
        }
      },
    },
    {
      ...(sortby === "Sales Dashboard" ? {
        key: "actions",
        title: "Actions",
        render: (data, type, row) => {
          return (
            <EditImg
              className="editSvg"
              onClick={() => {
                setUserPaymentDetails(null);
                setEditId(type);
                setShow(true);
              }}
            />
          );
        },
      } : {
        key: "actions",
        title: "Actions",
        render: (data, type, row) => {
          return (
            <div>
              <div id={type?.id} onClick={() => setActiveTooltipId(type?.id)}>
                <CiMenuKebab />
              </div>
              {activeTooltipId === type?.id && (
                <Tooltip
                  list={[
                    // {
                    //   render: sortby && (
                    //     <div
                    //       onClick={() => {
                    //         setUserPaymentDetails(null);
                    //         setEditId(type);
                    //         setShow(true);
                    //       }}
                    //     >
                    //       Edit
                    //     </div>
                    //   ),
                    // },
                    { name: "View User Activity" },
                  ]}
                  activeTooltipId={type?.id}
                  onSelect={(data) => {
                    console.log("data", data);
                    if (data?.name) {
                      navigate(`/user-stories?userId=${type?.id}`);
                    }
                  }}
                  closeActiveTooltipId={() => {
                    setActiveTooltipId("");
                  }}
                />
              )}
            </div>
          );
        },
      }
      ),
    },
  ];

  //default value setup on envoke
  function resetData() {
    setCurrentPage(1);
    setUsersData({});
  }

  //Get Users Data Based On DropDown Selection
  function getUsersData() {
    if (sortby !== isSameUsersData) {
      resetData();
      setIsSameUsersData(sortby);
    }
    if (sortby === "Sales Dashboard") {
      props.requestGetUsersData({ page: currentPage, search: search });
    } else {
      props.requestGetAllUsersData({ page: currentPage, search: search });
    }
  }

  function getSalesUserData(queryData) {
    props.requestGetUserData(queryData);
  }

  useEffect(() => {
    // pass key in query.get function to get value
    if (query.get("pid")) {
      const queryData = query.get("pid");
      getSalesUserData(queryData);
    }
  }, [query]);

  useEffect(() => {
    //ToGet The Users Data
    const requestUsersData = EventBus.on(
      USERS_TYPES.GET_USERS_DATA_REQUEST,
      () => {
        setIsUserDataFetching(true);
      }
    );
    const usersDataSuccess = EventBus.on(
      USERS_TYPES.GET_USERS_DATA_SUCCESS,
      (res) => {
        if (res) {
          setUsersData(res?.salesPaymentData);
          setIsUserDataFetching(false);
        }
      }
    );
    const usersDataFailure = EventBus.on(
      USERS_TYPES.GET_USERS_DATA_FAILED,
      () => {
        setIsUserDataFetching(false);
      }
    );

    getUsersData();

    //To Get The Users Data
    const requestAllUsersData = EventBus.on(
      USERS_TYPES.GET_ALL_USERS_DATA_REQUEST,
      () => {
        setIsUserDataFetching(true);
      }
    );
    const allUsersDataSuccess = EventBus.on(
      USERS_TYPES.GET_ALL_USERS_DATA_SUCCESS,
      (res) => {
        if (res) {
          setUsersData(res?.data);
          setIsUserDataFetching(false);
        }
      }
    );
    const allUsersDataFailure = EventBus.on(
      USERS_TYPES.GET_ALL_USERS_DATA_FAILED,
      () => {
        setIsUserDataFetching(false);
      }
    );

    //To create new Users
    const requestCreateUser = EventBus.on(
      USERS_TYPES.CREATE_USER_REQUEST,
      () => {
        setIsCreateUpdateUserLoading(true);
      }
    );
    const usersCreateSuccess = EventBus.on(
      USERS_TYPES.CREATE_USER_SUCCESS,
      (res) => {
        if (res) {
          getUsersData();
          setIsCreateUpdateUserLoading(false);
          setShow(false);
          alert(res?.message);
        }
      }
    );
    const userCreateFailure = EventBus.on(
      USERS_TYPES.CREATE_USER_FAILED,
      () => {
        setIsCreateUpdateUserLoading(false);
      }
    );

    //package List
    const getPackageList = EventBus.on(
      GLOBAL_TYPES.GET_PACKAGE_LIST_REQUEST,
      () => { }
    );
    const getPackageSuccess = EventBus.on(
      GLOBAL_TYPES.GET_PACKAGE_LIST_SUCCESS,
      (res) => {
        setPackageDropdownList(res?.data?.items);
      }
    );
    const getPackageFailure = EventBus.on(
      GLOBAL_TYPES.GET_PACKAGE_LIST_FAILED,
      () => { }
    );

    props.requestGetPackageList();

    //Update Users On Sales
    const requestUpdateSales = EventBus.on(
      USERS_TYPES.UPDATE_SALES_REQUEST,
      () => {
        setIsCreateUpdateUserLoading(true);
      }
    );
    const updateSalesSuccess = EventBus.on(
      USERS_TYPES.UPDATE_SALES_SUCCESS,
      (res) => {
        if (res) {
          getUsersData();
          setIsCreateUpdateUserLoading(false);
          setShow(false);
          alert(res?.message);
        }
      }
    );
    const updateSalesFailure = EventBus.on(
      USERS_TYPES.UPDATE_SALES_FAILED,
      () => {
        setIsCreateUpdateUserLoading(false);
      }
    );

    //ToGet The User payment Data
    const requestGetUserPaymetDetails = EventBus.on(
      USERS_TYPES.GET_USER_PAYMENT_DETAILS_REQUEST,
      () => { }
    );
    const requestGetUserPaymentDetailsSuccess = EventBus.on(
      USERS_TYPES.GET_USER_PAYMENT_DETAILS_SUCCESS,
      (res) => {
        if (res) {
          console.log(res);
          setUserPaymentDetails(res);
          // setIsUserDataFetching(false);
        }
      }
    );
    const requestGetUserPaymentDetailsFailure = EventBus.on(
      USERS_TYPES.GET_USER_PAYMENT_DETAILS_FAILED,
      () => {
        // setIsUserDataFetching(false);
      }
    );

    //ToGet The User Data from nitfication pannel
    const requestGetUserData = EventBus.on(
      USERS_TYPES.GET_USER_DATA_REQUEST,
      () => { }
    );
    const getUserDataSuccess = EventBus.on(
      USERS_TYPES.GET_USER_DATA_SUCCESS,
      (res) => {
        if (res) {
          setUserPaymentDetails(null);
          setEditId(res.salesUserData);
          setShow(true);
        }
      }
    );
    const getUserDataFailure = EventBus.on(
      USERS_TYPES.GET_USER_DATA_FAILED,
      () => { }
    );

    return () => {
      requestGetUserData();
      getUserDataSuccess();
      getUserDataFailure();

      requestUsersData();
      usersDataSuccess();
      usersDataFailure();

      requestCreateUser();
      usersCreateSuccess();
      userCreateFailure();

      getPackageList();
      getPackageSuccess();
      getPackageFailure();

      requestUpdateSales();
      updateSalesSuccess();
      updateSalesFailure();

      requestGetUserPaymetDetails();
      requestGetUserPaymentDetailsSuccess();
      requestGetUserPaymentDetailsFailure();

      requestAllUsersData();
      allUsersDataSuccess();
      allUsersDataFailure();
    };
  }, []);

  useEffect(() => {
    getUsersData();
  }, [currentPage, sortby, search]);

  //Creating New User
  const createUser = (data) => {
    props.requestCreateUser({ ...data, source: "sales-panel" });
  };

  //Updating Existing User
  const updateSales = (data) => {
    props.requestUpdateSales({ ...data, source: "sales-panel" });
  };

  //fetching user Payment details
  function fetchUserPaymentDetails(userData) {
    props.requestGetUserPaymetDetails({ paymentId: userData?.paymentId });
  }

  const selectUserCreatedAtOptions = [
    {
      value: true,
      label: "Sales Dashboard",
    },
    {
      value: false,
      label: "All",
    },
  ];

  return (
    <UserBox>
      <ContentHeader
        title="Users"
        btnText="Create New User"
        btnAction={() => {
          setEditId("");
          setShow(true);
        }}
      />
      <br />
      <Card>
        <FilterOptionsBox>
          <Dropdown
            placeholder=" Sales Dashboard "
            values={[{ label: "Sales Dashboard", value: "" }]}
            searchable={false}
            style={{ minWidth: "180px" }}
            options={selectUserCreatedAtOptions}
            label="Sort By User Created"
            onChange={(selectedValue) => {
              setSortBy(selectedValue[0]?.label);
            }}
          />
        </FilterOptionsBox>
      </Card>
      <br />
      <Card>
        <SearchBox>
          <span>Search</span>
          <Input onChange={(e) => setSearch(e.target.value)} />
        </SearchBox>
        <Table
          totalItems={usersData?.totalItems}
          columns={columns}
          data={usersData?.items}
          totalPages={usersData?.totalPages}
          pageNo={usersData?.currentPage}
          onPageChange={(currentPage) => {
            setCurrentPage(currentPage);
          }}
        />
      </Card>
      {show && (
        <AddEditUser
          userPaymentDetails={userPaymentDetails}
          fetchUserPaymentDetails={fetchUserPaymentDetails}
          show={show}
          onClose={handleOnClose}
          editId={editId}
          createUser={createUser}
          updateSales={updateSales}
          packageDropdownList={packageDropdownList}
          isCreateUpdateUserLoading={isCreateUpdateUserLoading}
        />
      )}
      {removeId && (
        <RemoveUser removeId={removeId} onClose={handleOnCloseRemoveMember} />
      )}
    </UserBox>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps, {
  requestGetUsersData,
  requestCreateUser,
  requestGetPackageList,
  requestUpdateSales,
  requestGetUserPaymetDetails,
  requestGetAllUsersData,
  requestGetUserData,
})(Users);
