import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import EventBus from "eventing-bus";
import { useNavigate } from "react-router-dom";
import {
  HeaderBox,
  LeftHeaderBox,
  RightHeaderBox,
  CoinBox,
  ThemeBox,
  NotificationBox,
  NotificationPannel,
  NotificationCard
} from "./header.styled";
import { updateThemeModeSuccess, requestGetNotifications, requestUpdateNotifications } from "../../../../redux/actions";
import { Button, Card } from "../../../../theme/components";
import DiamondImg from "../../../../assets/icons/diamond.svg";
import MoonImg from "../../../../assets/icons/moon.svg";
import SunImg from "../../../../assets/icons/sun.svg";
import { ReactComponent as NotificationImg } from "../../../../assets/icons/notification.svg"
import { USER_TYPES } from "../../../../redux/types";
import moment from "moment";

function Header(props) {
  let navigate = useNavigate();
  const notificationsrRef = useRef(null);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isAllNotificationRead, setIsAllNotificationRead] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const handleThemeToggle = (mode) => {
    props.updateThemeModeSuccess(mode);
  };

  useEffect(() => {
    getUserNotifications();
    //Notifications
    const getNotifications = EventBus.on(
      USER_TYPES.GET_USER_NOTIFICATIONS,
      () => { }
    );
    const getNotificationsSuccess = EventBus.on(
      USER_TYPES.GET_USER_NOTIFICATIONS_SUCCESS,
      (res) => {
        if (res?.data) {
          setNotifications(res?.data?.items)
          const notification = res.data.items.filter(item => item.hasRead === false);
          setIsAllNotificationRead(notification);
        }
      }
    );
    const getNotificationsFailure = EventBus.on(
      USER_TYPES.GET_USER_NOTIFICATIONS_FAILED,
      () => {
      }
    );

    //Notifications Update
    const updateNotifications = EventBus.on(
      USER_TYPES.UPDATE_USER_NOTIFICATIONS,
      () => { }
    );
    const updateNotificationsSuccess = EventBus.on(
      USER_TYPES.UPDATE_USER_NOTIFICATIONS_SUCCESS,
      (res) => {
        console.log(res);
        props.requestGetNotifications();
      }
    );
    const updateNotificationsFailure = EventBus.on(
      USER_TYPES.UPDATE_USER_NOTIFICATIONS_FAILED,
      () => {

      }
    );

    return () => {
      getNotifications();
      getNotificationsSuccess();
      getNotificationsFailure();

      updateNotifications();
      updateNotificationsSuccess();
      updateNotificationsFailure();
    }
  }, [])

  function updateNotifications(item) {
    if (item?.uuid && !item?.hasRead) {
      props.requestUpdateNotifications(item.uuid);
    }
    setIsNotificationsOpen(false)
    navigate(`/users?pid=${item?.metadata?.id}`)
  }

  function getUserNotifications() {
    props.requestGetNotifications({ page: currentPage, size: pageSize });
  }

  // user clicked out side of notification pannel 
  useEffect(() => {
    function handleClickOutside(event) {
      if (notificationsrRef.current && !notificationsrRef.current.contains(event.target)) {
        setIsNotificationsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationsrRef]);

  return (
    <HeaderBox>
      <LeftHeaderBox>
        {/* <img src={DiamondImg} alt="diamond" />
        Remaining Lab Coins: <CoinBox>0</CoinBox> */}
      </LeftHeaderBox>
      <RightHeaderBox>
        <div className={"mt-1 mx-3 " + `${isAllNotificationRead.length > 0 && 'notification-img'}`}
          onClick={() => {
            setIsNotificationsOpen(!isNotificationsOpen)
            setIsAllNotificationRead([])
          }}
          role="button"
        >
          <NotificationImg />
        </div>
        {
          isNotificationsOpen &&
          <NotificationPannel ref={notificationsrRef}>
            <h5 className="text-center py-2" >Payment link Expiration Of Users</h5>
            {notifications?.length > 0 ? notifications?.map(item => {
              return (<NotificationCard onClick={() => updateNotifications(item)}>
                <p>Expired Time : <span>{moment(item.updatedAt).format("MM/DD/YYYY")}</span></p>
                {/* <span> <span className="msg">message :</span> {item?.message}</span> */}
                <span>{item?.message}</span>
                {!item?.hasRead &&
                  <div className="dot"></div>
                }
              </NotificationCard>)
            }) : <p className="text-center">No notifications found</p>}
          </NotificationPannel>
        }
        {/* <NotificationBox>
          <img src={NotificationImg} alt="notification" />
          <div className="headerNotificationPoint" />
        </NotificationBox>
        <Button>Give Feedback</Button> */}
        {/* <ThemeBox>
          <img
            src={MoonImg}
            alt="moon"
            onClick={() => handleThemeToggle("dark")}
          />
          <div className="headerLine" />
          <img
            src={SunImg}
            alt="sun"
            onClick={() => handleThemeToggle("light")}
          />
        </ThemeBox> */}
        <Button
          primary
          small
        >Request a Feature</Button>
      </RightHeaderBox>
    </HeaderBox>
  );
}

function mapStateToProps(state) {
  return {
    theme: state?.theme,
    user: state?.user,
  };
}

export default connect(mapStateToProps, {
  updateThemeModeSuccess,
  requestGetNotifications,
  requestUpdateNotifications
})(Header);
