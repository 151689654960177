import { COMPANY_TYPES } from "../types";

export function requestGetCompany(payload) {
  return {
    type: COMPANY_TYPES.GET_COMPANY_REQUEST,
    payload,
  };
}

export function getCompanySuccess(payload) {
  return {
    type: COMPANY_TYPES.GET_COMPANY_SUCCESS,
    payload,
  };
}

export function getCompanyFailure(payload) {
  return {
    type: COMPANY_TYPES.GET_COMPANY_FAILED,
    payload,
  };
}

export function requestUpdateCompany(payload) {
  return {
    type: COMPANY_TYPES.UPDATE_COMPANY_REQUEST,
    payload,
  };
}

export function updateCompanySuccess(payload) {
  return {
    type: COMPANY_TYPES.UPDATE_COMPANY_SUCCESS,
    payload,
  };
}

export function updateCompanyFailure(payload) {
  return {
    type: COMPANY_TYPES.UPDATE_COMPANY_FAILED,
    payload,
  };
}

export function requestUploadCompanyImage(payload) {
  return {
    type: COMPANY_TYPES.UPLOAD_COMPANY_IMAGE_REQUEST,
    payload,
  };
}

export function uploadCompanyImageSuccess(payload) {
  return {
    type: COMPANY_TYPES.UPLOAD_COMPANY_IMAGE_SUCCESS,
    payload,
  };
}

export function uploadCompanyImageFailure(payload) {
  return {
    type: COMPANY_TYPES.UPLOAD_COMPANY_IMAGE_FAILED,
    payload,
  };
}
