import React, { useEffect, useState } from 'react'
import { Line } from "react-chartjs-2";
import EventBus from "eventing-bus";
import {
  Chart as ChartJS,
  registerables,
  CategoryScale
} from 'chart.js'
import styled from 'styled-components';
import desktopLogoImg from '../../assets/icons/desktopLogo.svg';
import { REVENUE_TYPES } from '../../redux/types';
import { getRevenueData } from '../../redux/actions'
import { connect } from 'react-redux';
import { Table } from '../../theme/components';

//Regitering The Required Tools Used In Graph **
ChartJS.register(
  ...registerables,
  CategoryScale,
)

const RevenueDashboard = (props) => {

  const [may, setMay] = useState([]);
  const [april, setApril] = useState([]);
  const [monthlyRevenue, setMonthlyRevenue] = useState();
  const [goal, setGoal] = useState();
  const [remaining, setRemaining] = useState();
  const [totalSales, setTotalSales] = useState();
  const [mayTables, setMayTables] = useState([]);
  const [currentMonth, setCurrentMonth] = useState('');
  let mayData = [
    {
      data: [],
      count: [],
      sales: [],
    }
  ]
  let aprilData = [
    {
      data: [],
      count: [],
      sales: [],
    }
  ]

  const mayTable = [];

  useEffect(() => {
    const revenueData = EventBus.on(
      REVENUE_TYPES.GET_REVENUE_DETAILS,
      (res) => {
        if (res) {
          console.log(res);
        } else {
          console.log("no data found ");
        }
      }
    )

    const interval = setInterval(() => {
      props.getRevenueData();
    }, 5000);
    props.getRevenueData();

    const revenueDataSuccess = EventBus.on(
      REVENUE_TYPES.GET_REVENUE_DETAILS_SUCCESS,
      (res) => {
        if (res) {

          const list = res.data;
          setMonthlyRevenue(res.monthlyRevenue)
          setRemaining(res.remainingTarget)
          setTotalSales(res.noOfSalesMonthly)
          setGoal(res.goals)
          setCurrentMonth(res.months[1]);
          const cm = res.months[1];
          const pm = res.months[0];
          const currentMonthSales = `${cm}Sales`;
          const currentMonthCount = `${cm}Count`;
          const prevMonthSales = `${pm}Sales`;
          const prevMonthCount = `${pm}Count`;
          console.log(currentMonthCount, currentMonthSales, prevMonthCount, prevMonthSales);
          list?.map((lt) => {
            mayData[0].data.push(lt.programManager);
            mayData[0].sales.push(lt[currentMonthSales]);
            mayData[0].count.push(lt[currentMonthCount]);
            aprilData[0].data.push(lt.programManager);
            aprilData[0].count.push(lt[prevMonthCount]);
            aprilData[0].sales.push(lt[prevMonthSales]);
            mayTable.push({
              programManager: lt.programManager,
              mayCount: lt[currentMonthCount],
              maySales: lt[currentMonthSales],
            })

          })
          setMay(mayData);
          setApril(aprilData);
          setMayTables(mayTable);
        } else {
          console.log("no data found");
        }
      }

    )
    const revenueDataFailure = EventBus.on(
      REVENUE_TYPES.GET_REVENUE_DETAILS_FAILURE,
      (res) => {
        if (res) {
          console.log(res);
        } else {
          console.log("no data found ");
        }
      }

    )
    return () => {
      revenueData();
      revenueDataFailure();
      revenueDataSuccess();
      clearInterval(interval);
    }
  }, [])


  const data = {

    labels: may[0]?.data,
    datasets: [
      {
        data: may[0]?.sales,
        borderColor:
          '#6c5ef9'
        ,
        backgroundColor: "#f0eeff",
        borderWidth: 2,
        label: 'Sales',
        count: may[0]?.count

      }
    ]
  }
  const options = {

    plugins: {
      tooltip: {
        yAlign: 'bottom',
        displayColors: false,
        titleMarginBottom: 0,
        callbacks: {
          title: function (e) {
            return "Count : " + e[0].dataset.count[e[0].dataIndex]
          },
          beforeTitle: function (e) {
            return e[0].label
          }
        },
      },
      legend: { display: false },
      title: {
        display: true,
        text: `Month - ${currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1)}`,
        position: "top",

      }
    },

  }
  const row = mayTables.sort((a, b) => b.maySales - a.maySales);
  const topThree = row.slice(0, 3);
  const columns = [
    {
      key: "programManager",
      title: "Program Manager",
      render: (data, type, row) => {
        return type?.programManager;
      },
    },
    {
      key: "mayCount",
      title: `${currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1)} Count`,
      render: (data, type, row) => {
        return type?.mayCount;
      },
    },
    {
      key: "MaySales",
      title: `${currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1)} Sales`,
      render: (data, type, row) => {
        return type?.maySales;
      },
    },


  ];

  return (
    <MainContainer>
      <NavbarLogo> <ImageLogo src={desktopLogoImg} alt='logo' /></NavbarLogo>
      <TotalContainer>
        <Itemcontainer>
          <ItemBox>
            <Title> Monthly Revenue</Title>
            <Total>{monthlyRevenue}</Total>
          </ItemBox>
          <ItemBox>
            <Title>Goal</Title>
            <Total>{goal}</Total>

          </ItemBox>
          <ItemBox>
            <Title>Remaining Target</Title>
            <Total>{remaining}</Total>

          </ItemBox>
          <ItemBox>
            <Title>No. of Sales (Monthly)</Title>
            <Total>{totalSales}</Total>
          </ItemBox>
        </Itemcontainer>
      </TotalContainer>

      {(may?.length > 0 && april?.length > 0) ?
        <BarContainer >
          <ChartBox>
            <div style={{
              fontSize: ".9rem",
              fontWeight: "500",
              marginBottom: "1rem",
              marginTop: '.8rem'
            }}>
              Top Performers
            </div>
            <div style={{ width: "90%", height: "40vh" }}>
              {<Table totalItems={topThree}
                columns={columns}
                data={topThree} />}
            </div>
          </ChartBox>
          <Box>
            <Line data={data} options={options} />
          </Box>

        </BarContainer> :
        <div style={{ textAlign: 'center', marginTop: "1rem", fontSize: "2rem" }}>
          Loading...
        </div>}
    </MainContainer>
  )
}

const Title = styled.div`
 font-size:0.67rem;
 font-weight:400;

`
const Total = styled.div`
font-size:1.8rem;
font-weight:400;
`
const Box = styled.div`
width:50%;
display:flex;
justify-content:center;
align-items:center;


`
const MainContainer = styled.div`
  width:100%;
`
const BarContainer = styled.div`
   display:flex;
   justify-content:center;
   align-tems:center;
   width:100%;
   gap:3rem;


`

const ChartBox = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width:40%;
  padding-top:1rem;
  padding-bottom:2rem;
  background-color:#fff;
  border-radius:10px;
  flex-direction:column;
`
const NavbarLogo = styled.div`
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  height:10vh;
`

const ImageLogo = styled.img`
  height:5vh;
  width:auto;
`
const TotalContainer = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  justify-content:center;
  align-items:center;
`
const Itemcontainer = styled.div`
  width:95%;
  background-color:#fff;
  min-height:15vh;
  height:fit-content;
  border-radius:10px;
  margin-bottom:1rem;
  display:flex;
  justify-content:space-evenly;
  align-items:center;
  gap:1rem;
  // flex-wrap:wrap;
  flex-direction:row;
  padding:1rem;
`

const ItemBox = styled.div`
  flex-direction:column;
  background-color:#f0eeff;
  padding:1rem;
  border-radius:10px;
  min-width:200px;
  width:fit-content;
  font-weight:600;

  
`
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps, {
  getRevenueData
})(RevenueDashboard);
