import { API_END_POINTS } from "../endpoints";
import { ApiRequest } from "../../helpers";
import { Convert } from "../../helpers/convert.helper";

export function getRegisteredUsers(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_REGISTERED_USERS + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function getUsersByPaymentDeatils(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_USERS_BY_PAYMENT_DETAILS + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function getUsersByPackage(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_USERS_BY_PACKAGE + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function getCloudLabsList(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_CLOUD_LABS_LIST + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function getLabCoinsCount(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_LAB_CREDITS_COUNT + `${query && "?" + query}`,
  }).then((response) => response.data);
}