import { takeEvery, call, put } from "redux-saga/effects";
import { getCountryListFailure, getCountryListSuccess, getPackageListFailure, getPackageListSuccess , getCouponsListFailure , getCouponsListSuccess } from "../actions";
  import { GLOBAL_TYPES } from "../types";
  import { getCountryList , getPackageList , getCoupsList} from "../apis";

  export function* watchGlobalRequests() {
    yield takeEvery(GLOBAL_TYPES.GET_COUNTRY_LIST_REQUEST, requestGetCountryList);
    yield takeEvery(GLOBAL_TYPES.GET_PACKAGE_LIST_REQUEST, requestGetPackageList);
    yield takeEvery(GLOBAL_TYPES.GET_COUNTRY_LIST_REQUEST,requestGetCouponsList);
  }

  function* requestGetCountryList(action) {
    try {
      const data = yield call(getCountryList, action.payload);
      yield put(getCountryListSuccess(data));
    } catch (e) {
      yield put(getCountryListFailure(e));
    }
  }

  function* requestGetPackageList(action) {
    try {
      const data = yield call(getPackageList, action.payload);
      yield put(getPackageListSuccess(data));
    } catch (e) {
      yield put(getPackageListFailure(e));
    }
  }

  function* requestGetCouponsList(action) {
    try {
      const data = yield call(getCoupsList, action.payload);
      yield put(getCouponsListSuccess(data));
    } catch (e) {
      yield put(getCouponsListFailure(e));
    }
  }