import React, { useEffect, useState } from 'react'
import { SearchBox, DemoCallsBox } from './demoCall.styled'
import { Table, Card, Input, ContentHeader, TableLoader, Tooltip, StatusChip } from '../../theme/components'
import { requestGetDemoCallsData, requestUpdateDemoCallsData } from '../../redux/actions';
import { DEMOCALLS_TYPES } from '../../redux/types';
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { CiMenuKebab } from "react-icons/ci";

function DemoCall(props) {
  const [search, setSearch] = useState('');
  const [isDemoCallsDataFetching, setIsDemoCallsDataFetching] = useState(true);
  const [demoCallsData, setDemoCallsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)
  const [activeTooltipId, setActiveTooltipId] = useState("");

  useEffect(() => {
    //ToGet The Users Data
    const requestDemoCallsData = EventBus.on(
      DEMOCALLS_TYPES.GET_DEMOCALLS_DATA_REQUEST,
      () => {
        setIsDemoCallsDataFetching(true);
      }
    );
    const demoCallsDataSuccess = EventBus.on(
      DEMOCALLS_TYPES.GET_DEMOCALLS_DATA_SUCCESS,
      (res) => {
        if (res) {
          console.log(res)
          setDemoCallsData(res);
          setIsDemoCallsDataFetching(false);
        }
      }
    );
    const demoCallsDataFailure = EventBus.on(
      DEMOCALLS_TYPES.GET_DEMOCALLS_DATA_FAILED,
      () => {
        setIsDemoCallsDataFetching(false);
      }
    );

    //ToUpdate The Demo Calls Users Data
    const requestUpdateDemoCallsData = EventBus.on(
      DEMOCALLS_TYPES.UPDATE_DEMOCALLS_DATA_REQUEST,
      () => {
        // setIsDemoCallsDataFetching(true);
      }
    );
    const updateDemoCallsDataSuccess = EventBus.on(
      DEMOCALLS_TYPES.UPDATE_DEMOCALLS_DATA_SUCCESS,
      (res) => {
        if (res) {
          console.log(res);
          // getDemoCalls();
        }
      }
    );
    const updateDemoCallsDataFailure = EventBus.on(
      DEMOCALLS_TYPES.UPDATE_DEMOCALLS_DATA_FAILED,
      () => {
        // setIsDemoCallsDataFetching(false);
      }
    );

    getDemoCalls();

    return () => {
      requestDemoCallsData();
      demoCallsDataSuccess();
      demoCallsDataFailure();

      requestUpdateDemoCallsData();
      updateDemoCallsDataSuccess();
      updateDemoCallsDataFailure();
    }
  }, [])

  useEffect(() => {
    getDemoCalls();
  }, [currentPage, search]);

  // to get the demo calls data
  const getDemoCalls = () => {
    props.requestGetDemoCallsData({ page: currentPage, search: search });
  }

  const updateUserData = (userData, status) => {
    userData.status = status
    props.requestUpdateDemoCallsData(userData)
  }
  //Columns Declaration For The User Table
  const columns = [
    {
      key: "Name",
      title: "Name",
      render: (data, type, row) => {
        return type?.name;
      },
    },
    {
      key: "email",
      title: "Email",
      render: (data, type, row) => {
        return type?.email;
      },
    },
    {
      key: "phone",
      title: "Phone",
      render: (data, type, row) => {
        return type?.phone;
      },
    },
    {
      key: "companyName",
      title: "Company Name",
      render: (data, type, row) => {
        return type?.companyName;
      },
    },
    {
      key: "designation",
      title: "Designation",
      render: (data, type, row) => {
        return type?.designation;
      },
    },
    {
      key: "call-Status",
      title: "Status",
      render: (data, type, row) => {
        if (type?.status) {
          return <StatusChip title='Complete' />
        } else {
          return <StatusChip title='InComplete' />
        }
      },
    },
    {
      key: "actions",
      title: "Actions",
      render: (data, type, row) => {
        return (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} id={type?.id} onClick={() => setActiveTooltipId(type?.id)}>
              <CiMenuKebab style={{ cursor: 'pointer' }} />
            </div>
            {activeTooltipId === type?.id && (
              <Tooltip
                list={[
                  {
                    ...(type.status ? {
                      render: (
                        <div
                          onClick={() => {
                            updateUserData(type, false)
                          }}
                        >
                          Mark As InComplete
                        </div>
                      )
                    } : {
                      render: (
                        <div
                          onClick={() => {
                            updateUserData(type, true)
                          }}
                        >
                          Mark As Complete
                        </div>
                      )
                    }
                    )
                  }
                ]
                }
                activeTooltipId={type?.id}
                closeActiveTooltipId={() => {
                  setActiveTooltipId("");
                }}
              />
            )}
          </div >
        );
      },
    }
  ];

  return (
    <DemoCallsBox>
      <ContentHeader
        title="Demo Calls"
      />
      <br />
      <Card>
        {
          isDemoCallsDataFetching ? <TableLoader isLoading={isDemoCallsDataFetching} /> :
            <>
              <SearchBox>
                <span>Search</span>
                <Input onChange={(e) => setSearch(e.target.value)} />
              </SearchBox>
              <Table
                totalItems={demoCallsData}
                columns={columns}
                data={demoCallsData}
                totalPages={1}
                pageNo={1}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                }}
              />
            </>
        }
      </Card>
    </DemoCallsBox>
  )
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  requestGetDemoCallsData,
  requestUpdateDemoCallsData
})(DemoCall);