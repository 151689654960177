import { takeEvery, call, put } from "redux-saga/effects";
import {
  getUsersDataFailure,
  getUsersDataSuccess,
  createUserFailure,
  createUserSuccess,
  updateSalesSuccess,
  updateSalesFailure,
  requestGetUserPaymetDetailsFailure,
  requestGetUserPaymetDetailsSuccess,
  getAllUsersDataFailure,
  getAllUsersDataSuccess,
  getUserDataFailure,
  getUserDataSuccess
} from "../actions";
import { USERS_TYPES } from "../types";
import { getUsersData, createUser, updateSales, getUserPaymentDetails, getAllUsersData, getSalesUserData } from "../apis/users.api";

export function* watchUsersRequests() {
  yield takeEvery(USERS_TYPES.GET_USERS_DATA_REQUEST, requestGetUsersData);
  yield takeEvery(USERS_TYPES.GET_ALL_USERS_DATA_REQUEST, requestGetAllUsersData);
  yield takeEvery(USERS_TYPES.GET_USER_PAYMENT_DETAILS_REQUEST, requestGetUserPaymentDetails);
  yield takeEvery(USERS_TYPES.CREATE_USER_REQUEST, requestCreateUser);
  yield takeEvery(USERS_TYPES.UPDATE_SALES_REQUEST, requestUpdateSales);
  yield takeEvery(USERS_TYPES.GET_USER_DATA_REQUEST, requestGetSalesUserData);
}

function* requestGetUsersData(action) {
  try {
    const data = yield call(getUsersData, action.payload);
    yield put(getUsersDataSuccess(data));
  } catch (e) {
    yield put(getUsersDataFailure(e));
  }
}

function* requestCreateUser(action) {
  try {
    const data = yield call(createUser, action.payload);
    yield put(createUserSuccess(data));
  } catch (e) {
    yield put(createUserFailure(e));
  }
}

function* requestUpdateSales(action) {
  try {
    const data = yield call(updateSales, action.payload);
    yield put(updateSalesSuccess(data));
  } catch (e) {
    yield put(updateSalesFailure(e));
  }
}

function* requestGetUserPaymentDetails(action) {
  try {
    const data = yield call(getUserPaymentDetails, action.payload);
    yield put(requestGetUserPaymetDetailsSuccess(data));
  } catch (e) {
    yield put(requestGetUserPaymetDetailsFailure(e));
  }
}


function* requestGetAllUsersData(action) {
  try {
    const data = yield call(getAllUsersData, action.payload);
    yield put(getAllUsersDataSuccess(data));
  } catch (e) {
    yield put(getAllUsersDataFailure(e));
  }
}

function* requestGetSalesUserData(action) {
  try {
    const data = yield call(getSalesUserData, action.payload);
    yield put(getUserDataSuccess(data));
  } catch (e) {
    yield put(getUserDataFailure(e));
  }
}