import styled from "styled-components";
import { COLORS } from "../../colors";
export const CalenderBox = styled.div`
.react-calender-input{
  min-height: ${(props) => props.theme.input.minHeight};
  width: ${(props) => `calc(100% - 22px)`};
  max-width: 100%;
  font-size: ${(props) => props.theme.input.fontSize};
  border-radius: ${(props) => props.theme.input.borderRadius};
  border: ${(props) => props.theme.input.border};
  padding: ${(props) => props.theme.input.padding};
  outline-color: ${(props) => props.theme.input.outlineColor};

  ${(props) =>
    props.errorMessage &&
    `outline-color:${props.theme.input.error.errorOutlineColor};
    border:${props.theme.input.error.errorBorder};`};

  ${(props) =>
    props.disabled &&
    `background-color:${props.theme.input.disabled.backgroundColor};
    border:${props.theme.input.disabled.border};
           color:${props.theme.input.disabled.color};
           cursor: ${props.theme.input.disabled.cursor};
           `};
}

.react-datepicker{
    background-color:white;
}
.react-datepicker__month-container{
    background-color:white;
}
.react-datepicker__day, 
.react-datepicker__month-text,
 .react-datepicker__quarter-text, 
 .react-datepicker__year-text {
    width:2rem;
    height:2rem;
    cursor: pointer;
    &:hover{
        background-color: ${COLORS.blue};
        border-radius: 100%;
        color: #fff;
    }
}
.react-datepicker__day--today{
    color:${COLORS.blue};
    font-weight:bold;
    font-size:0.9rem
}
.react-datepicker__header{
background-color:${COLORS.lightBlue1};
}
.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range,
 .react-datepicker__day--in-range, 
 .react-datepicker__month-text--selected, 
 .react-datepicker__month-text--in-selecting-range, 
 .react-datepicker__month-text--in-range, 
 .react-datepicker__quarter-text--selected, 
 .react-datepicker__quarter-text--in-selecting-range, 
 .react-datepicker__quarter-text--in-range, 
 .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
   .react-datepicker__year-text--in-range {
    border-radius: 100%;
    background-color: ${COLORS.blue};
    color: #fff;
}
.react-datepicker-popper .react-datepicker__triangle {
    display:none;
}
`
export const CalenderLabel = styled.div`
  margin-bottom: 5px;
  font-size:0.9rem;
`;

export const ErrorBox = styled.div`
  ${(props) => `color:${props.theme.input.error.color};
  font-size:${props.theme.input.error.fontSize};
  font-weight:${props.theme.input.error.fontWeight};`};
`;