import { API_END_POINTS } from "../endpoints";
import { ApiRequest } from "../../helpers";

export function getCompany() {
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_COMPANY,
  }).then((response) => response.data);
}

export function updateCompany(params) {
  return ApiRequest.fetch({
    method: "put",
    url: API_END_POINTS.UPDATE_COMPANY,
    data: params,
  }).then((response) => response.data);
}

export function uploadCompanyImage(params) {
  return ApiRequest.fetch({
    method: "post",
    url: API_END_POINTS.UPLOAD_COMPANY_IMAGE,
    data: params,
    headers: {
      "content-type": "multipart/form-data",
    },
  }).then((response) => response.data);
}
