import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const ReferalsBox = styled.div`
  padding: 20px 20px;
`;

export const Ticket = styled.div`
  display: flex;
  padding: 15px;
  /* max-width:350px; */
  ${(props) => `background:${COLORS[props.color]};`};
  border-radius: 10px;
  .left-ticket {
    padding-right: 15px;
  }
  p {
    margin-bottom: 5px;
  }
  .subData{
    font-size:12px
  }
`;

export const TicketBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.25rem;

`;