import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const SearchBox = styled.div`
padding: 5px 10px;
display:flex;
gap:20px;
align-items:center;
justify-content:end;
`

export const DemoCallsBox = styled.div`
  padding: 20px 15px 0px 15px;
`;