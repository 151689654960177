import { REVENUE_TYPES } from "../types";

export function getRevenueData(payload) {
  return {
    type: REVENUE_TYPES.GET_REVENUE_DETAILS,
    payload,
  };
}
export function getRevenueDataSuccess(payload) {
    return {
      type: REVENUE_TYPES.GET_REVENUE_DETAILS_SUCCESS,
      payload,
    };
  }
export function getRevenueDataFailure(payload) {
    return {
      type: REVENUE_TYPES.GET_REVENUE_DETAILS_FAILURE,
      payload,
    };
  }