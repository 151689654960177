import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const CouponBox = styled.div`
.discount{
  display: flex;
  justify-content: start;
  align-items: center;
}
.couponData{
  position: relative;
  padding-left: 0.9rem;
}
.green-notification{
      position: absolute;
      content: "";
      background:green;
      border-radius:100%;
      padding: 5px;
      position: absolute;
      left: 0rem;
      top: 0.5rem;
  }
  .red-notification{
      position: absolute;
      content: "";
      background:red;
      border-radius:100%;
      padding: 5px;
      position: absolute;
      left: 0rem;
      top: 0.5rem;
  }
  padding: 20px 20px;
  .view-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .editSvg{
    cursor:pointer;
    &:hover {
  path[stroke] {
    stroke: ${COLORS.darkBlue};
  }
}
  }
  .coupon-details{
    display: flex;
    flex-wrap: nowrap;
    gap: 15px;
    flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  span{
    font-size: 1rem;
    font-weight: 400;
    margin-left: 10px;
    /* background:#D9D9D9; */
    padding: 1px 10px;
  }
  h3{
    font-weight: 500 !important;
    font-size: 1rem;
  }
  .question-icon{
    position: relative;
    cursor: pointer;
    &:hover{
    path[stroke] {
        stroke: ${COLORS.blue};
      }
    }
  }
  }
`;
