import styled from "styled-components";
import { COLORS } from "../../colors";
import { FONT_SIZES } from "../../fontSizes";
import { FONT_WEIGHTS } from "../../fontWeights";

export const UploadBox = styled.div``;
export const UploadButton = styled.button`
  min-height: ${(props) => props.theme.upload.minHeight};
  width: 100%;
  max-width: ${(props) => props?.maxWidth || props.theme.upload.maxWidth};
  font-size: ${(props) => props.theme.upload.fontSize};
  border-radius: ${(props) => props.theme.upload.borderRadius};
  border: ${(props) => props.theme.upload.border};
  padding: ${(props) => props.theme.upload.padding};
  outline-color: ${(props) => props.theme.upload.outlineColor};
  background-color: ${(props) => props.theme.upload.backgroundColor};
  margin-bottom: 10px;
  span {
    margin-right: 5px;
  }

  ${(props) =>
    props.disabled &&
    `background-color:${props.theme.upload.disabled.backgroundColor};
  border:${props.theme.upload.disabled.border};
         color:${props.theme.upload.disabled.color};
         cursor: ${props.theme.upload.disabled.cursor};
         `};

  ${(props) =>
    props.isError &&
    `outline-color:${props.theme.upload.error.errorOutlineColor};
    color:${props.theme.upload.error.color};
  border:${props.theme.upload.error.errorBorder};`};
`;

export const ErrorBox = styled.div`
  ${(props) => `color:${props.theme.upload.error.color};
font-size:${props.theme.upload.error.fontSize};
font-weight:${props.theme.upload.error.fontWeight};`};
`;

export const UploadLabel = styled.div`
  margin-bottom: 5px;
`;

export const UploadedFilesBox = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
export const UploadedFileLabel = styled.div`
  margin-bottom: 5px;
`;
export const NoteBox = styled.div`
  margin-bottom: 5px;
  color: ${(props) => props.theme.upload.noteColor};
`;

export const PreviewWrapperBox = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  color: ${COLORS.white};
  opacity: 0;
  cursor: pointer;
  transition: opacity 300ms ease-in;
`;

export const UploadedFileImage = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  position: relative;
  border: 1px solid ${COLORS.grey03};
  img {
    width: 86px;
    height: 86px;
    border-radius: 10px;
    object-fit: cover;
    position: relative;
    left: 6px;
    top: 6px;
  }
  &:hover ${PreviewWrapperBox} {
    opacity: 1;
    transition: opacity 300ms ease-out;
  }
`;

export const PreviewImageCloseBox = styled.div`
  cursor: pointer;
  position: absolute;
  color: ${COLORS.white};
  right: 30px;
  top: 30px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px 30px;
  font-size: ${FONT_SIZES.BODY_LARGE};
  box-shadow: 3px 3px 15px ${COLORS.black};
  border-radius: 10px;
`;

export const PreviewImageBox = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 100;
  background: rgba(0, 0, 0, 0.77);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export const ImageEditIcon = styled.div`
  position: absolute;
  right: 3px;
  top: -12px;
  z-index: 2;
  img {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  &:hover + ${PreviewWrapperBox} {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
`;
