export const USER_TYPES = {
  SIGN_IN_REQUEST: "SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILED: "SIGN_IN_FAILED",

  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILED: "GET_USERS_FAILED",

  OTP_VERIFY_REQUEST: "OTP_VERIFY_REQUEST",
  OTP_VERIFY_SUCCESS: "OTP_VERIFY_SUCCESS",
  OTP_VERIFY_FAILED: "OTP_VERIFY_FAILED",

  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",

  GET_USER_NOTIFICATIONS: "GET_USER_NOTIFICATIONS",
  GET_USER_NOTIFICATIONS_SUCCESS: "GET_USER_NOTIFICATIONS_SUCCESS",
  GET_USER_NOTIFICATIONS_FAILED: "GET_USER_NOTIFICATIONS_FAILED",

  UPDATE_USER_NOTIFICATIONS: "UPDATE_USER_NOTIFICATIONS",
  UPDATE_USER_NOTIFICATIONS_SUCCESS: "UPDATE_USER_NOTIFICATIONS_SUCCESS",
  UPDATE_USER_NOTIFICATIONS_FAILED: "UPDATE_USER_NOTIFICATIONS_FAILED",
};
