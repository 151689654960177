import { API_END_POINTS } from "../endpoints";
import { ApiRequest } from "../../helpers";
import { Convert } from "../../helpers/convert.helper";

export function ReferralCount(params) {
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_REFERRAL_COUNT,
    data: params,
  }).then((response) => response.data);
}

export function ReferralCountById(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_REFERRAL_COUNT_BY_ID + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function DistinctReferral(queryData) {
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_DISTINCT_REFERRAL,
  }).then((response) => response.data);
}
