import { takeEvery, call, put } from "redux-saga/effects";
import {
  getCompanySuccess,
  getCompanyFailure,
  updateCompanySuccess,
  updateCompanyFailure,
  uploadCompanyImageSuccess,
  uploadCompanyImageFailure,
} from "../actions";
import { COMPANY_TYPES } from "../types";
import { getCompany, updateCompany, uploadCompanyImage } from "../apis";

export function* watchCompanyRequests() {
  yield takeEvery(COMPANY_TYPES.GET_COMPANY_REQUEST, requestGetCompany);
  yield takeEvery(COMPANY_TYPES.UPDATE_COMPANY_REQUEST, requestUpdateCompany);
  yield takeEvery(
    COMPANY_TYPES.UPLOAD_COMPANY_IMAGE_REQUEST,
    requestUploadCompanyImage
  );
}

function* requestGetCompany(action) {
  try {
    const data = yield call(getCompany, action.payload);
    yield put(getCompanySuccess(data));
  } catch (e) {
    yield put(getCompanyFailure(e));
  }
}
function* requestUpdateCompany(action) {
  try {
    const data = yield call(updateCompany, action.payload);
    yield put(updateCompanySuccess(data));
  } catch (e) {
    yield put(updateCompanyFailure(e));
  }
}
function* requestUploadCompanyImage(action) {
  try {
    const data = yield call(uploadCompanyImage, action.payload);
    yield put(uploadCompanyImageSuccess(data));
  } catch (e) {
    yield put(uploadCompanyImageFailure(e));
  }
}
