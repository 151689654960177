import { takeEvery, call, put } from "redux-saga/effects";
import { getDemoCallsDataFailure, getDemoCallsDataSuccess, updateDemoCallsDataFailure, updateDemoCallsDataSuccess } from "../actions";
import { DEMOCALLS_TYPES } from "../types";
import { getDemoCallsData, updateDemoCallsData } from "../apis";

export function* watchDemoCallsRequests() {
    yield takeEvery(DEMOCALLS_TYPES.GET_DEMOCALLS_DATA_REQUEST, requestGetDemoCallsData);
    yield takeEvery(DEMOCALLS_TYPES.UPDATE_DEMOCALLS_DATA_REQUEST, requestUpdateDemoCallsData);
}

function* requestGetDemoCallsData(action) {
    try {
        const data = yield call(getDemoCallsData, action.payload);
        yield put(getDemoCallsDataSuccess(data));
    } catch (e) {
        yield put(getDemoCallsDataFailure(e));
    }
}

function* requestUpdateDemoCallsData(action) {
    try {
        const data = yield call(updateDemoCallsData, action.payload);
        yield put(updateDemoCallsDataSuccess(data));
    } catch (e) {
        yield put(updateDemoCallsDataFailure(e));
    }
}