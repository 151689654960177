import { all } from "redux-saga/effects";
import { watchUserRequests } from "./user.saga";
import { watchCompanyRequests } from "./company.saga";
import { watchDashboardRequests } from "./dashboard.saga";
import { watchGlobalRequests } from "./global.saga";
import { watchCouponsRequests } from "./coupon.saga";
import { watchUsersRequests } from "./users.saga";
import { watchUserStoriesUsersRequests } from "./userStories.saga";
import { watchReferralRequests } from "./referral.saga"
import { watchDemoCallsRequests } from "./demoCalls.saga"
import { watchRevenueRequest } from "./revenue.saga";
export default function* rootSaga() {
  yield all([watchUserRequests(),
  watchCompanyRequests(),
  watchDashboardRequests(),
  watchGlobalRequests(),
  watchUsersRequests(),
  watchCouponsRequests(),
  watchReferralRequests(),
  watchDemoCallsRequests(),
  watchUserStoriesUsersRequests(),
  watchRevenueRequest(),
]);
}
