import { DEMOCALLS_TYPES } from "../types";

//get Demo Users
export function requestGetDemoCallsData(payload) {
  return {
    type: DEMOCALLS_TYPES.GET_DEMOCALLS_DATA_REQUEST,
    payload,
  };
}

export function getDemoCallsDataSuccess(payload) {
  return {
    type: DEMOCALLS_TYPES.GET_DEMOCALLS_DATA_SUCCESS,
    payload,
  };
}

export function getDemoCallsDataFailure(payload) {
  return {
    type: DEMOCALLS_TYPES.GET_DEMOCALLS_DATA_FAILED,
    payload,
  };
}

//update Demo Users
export function requestUpdateDemoCallsData(payload) {
  return {
    type: DEMOCALLS_TYPES.UPDATE_DEMOCALLS_DATA_REQUEST,
    payload,
  };
}

export function updateDemoCallsDataSuccess(payload) {
  return {
    type: DEMOCALLS_TYPES.UPDATE_DEMOCALLS_DATA_SUCCESS,
    payload,
  };
}

export function updateDemoCallsDataFailure(payload) {
  return {
    type: DEMOCALLS_TYPES.UPDATE_DEMOCALLS_DATA_FAILED,
    payload,
  };
}
