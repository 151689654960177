export const DASHBOARD_TYPES = {
    GET_USERS_REQUEST: "GET_USERS_REQUEST",
    GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
    GET_USERS_FAILED: "GET_USERS_FAILED",

    GET_REGISTERED_USERS_REQUEST: "GET_REGISTERED_USERS_REQUEST",
    GET_REGISTERED_USERS_SUCCESS: "GET_REGISTERED_USERS_SUCCESS",
    GET_REGISTERED_USERS_FAILED: "GET_REGISTERED_USERS_FAILED",

    GET_USERS_BY_PAYMENT_DETAILS_REQUEST:"GET_USERS_BY_PAYMENT_DETAILS_REQUEST",
    GET_USERS_BY_PAYMENT_DETAILS_SUCCESS:"GET_USERS_BY_PAYMENT_DETAILS_SUCCESS",
    GET_USERS_BY_PAYMENT_DETAILS_FAILED:"GET_USERS_BY_PAYMENT_DETAILS_FAILED",

    GET_USERS_BY_PACKAGE_REQUEST:"GET_USERS_BY_PACKAGE_REQUEST",
    GET_USERS_BY_PACKAGE_SUCCESS:"GET_USERS_BY_PACKAGE_SUCCESS",
    GET_USERS_BY_PACKAGE_FAILED:"GET_USERS_BY_PACKAGE_FAILED",

    GET_LAB_CREDITS_COUNT_REQUEST:"GET_LAB_CREDITS_COUNT_REQUEST",
    GET_LAB_CREDITS_COUNT_SUCCESS:"GET_LAB_CREDITS_COUNT_SUCCESS",
    GET_LAB_CREDITS_COUNT_FAILED:"GET_LAB_CREDITS_COUNT_FAILED",

    GET_CLOUD_LABS_LIST_REQUEST:"GET_CLOUD_LABS_LIST_REQUEST",
    GET_CLOUD_LABS_LIST_SUCCESS:"GET_CLOUD_LABS_LIST_SUCCESS",
    GET_CLOUD_LABS_LIST_FAILED:"GET_CLOUD_LABS_LIST_FAILED"
  };
  