import { call, put, takeEvery } from "redux-saga/effects";
import { REVENUE_TYPES } from "../types";
import {getRevenueDataApi } from '../apis';
import { getRevenueData, getRevenueDataFailure,getRevenueDataSuccess } from "../actions";

export function* watchRevenueRequest() {
    yield takeEvery(REVENUE_TYPES.GET_REVENUE_DETAILS, requestGetRevenueData);
    
}

function* requestGetRevenueData(action) {
    try {
        const data = yield call(getRevenueDataApi, action.payload);
        yield put(getRevenueDataSuccess(data));
    } catch (e) {
        yield put(getRevenueDataFailure(e));
    }
}