import { getDecryptedData } from "../../utility/utils";
import { REFERRAL_TYPES } from "../types";

export function requestReferralCount(payload) {
  return {
    type: REFERRAL_TYPES.GET_REFERRAL_COUNT_REQUEST,
    payload,
  };
}

export function referralCountSuccess(payload) {
  if (payload && payload.data) {
    payload = getDecryptedData(payload.data);
  }
  return {
    type: REFERRAL_TYPES.GET_REFERRAL_COUNT_SUCCESS,
    payload,
  };
}

export function referralCountFailure(payload) {
  return {
    type: REFERRAL_TYPES.GET_REFERRAL_COUNT_FAILED,
    payload,
  };
}

export function requestReferralCountByID(payload) {
    return {
      type: REFERRAL_TYPES.GET_REFERRAL_COUNT_ID_REQUEST,
      payload,
    };
  }
  
  export function referralCountByIDSuccess(payload) {
    if (payload && payload.data) {
      payload = getDecryptedData(payload.data);
    }
    return {
      type: REFERRAL_TYPES.GET_REFERRAL_COUNT_ID_SUCCESS,
      payload,
    };
  }
  
  export function referralCountByIDFailure(payload) {
    return {
      type: REFERRAL_TYPES.GET_REFERRAL_COUNT_ID_FAILED,
      payload,
    };
  }
  


export function requestReferralDistinct(payload) {
    return {
      type: REFERRAL_TYPES.GET_REFERRAL_DISTINCT_REQUEST,
      payload,
    };
  }
  
export function referralDistinctSuccess(payload) {
    if (payload && payload.data) {
    payload = getDecryptedData(payload.data);
    }
    return {
    type: REFERRAL_TYPES.GET_REFERRAL_DISTINCT_SUCCESS,
    payload,
    };
}

export function referralDistinctFailure(payload) {
    return {
    type: REFERRAL_TYPES.GET_REFERRAL_DISTINCT_FAILED,
    payload,
    };
}