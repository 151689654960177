const BASE_URL = process.env.REACT_APP_API_URL;

export const API_END_POINTS = {
  SIGN_IN: `${BASE_URL}/auth/admin/signIn`,
  // SIGN_IN: `${BASE_URL}/auth/signIn`,
  OTP_VERIFY: `${BASE_URL}/otp/verify`,

  GET_COMPANY: `${BASE_URL}/company/settings`,
  UPDATE_COMPANY: `${BASE_URL}/company/update`,
  UPLOAD_COMPANY_IMAGE: `${BASE_URL}/upload`,

  GET_USERS: `${BASE_URL}/analytics/users/count/total-user`,
  GET_REGISTERED_USERS: `${BASE_URL}/analytics/users/count/registered-user`,
  GET_USERS_BY_PAYMENT_DETAILS: `${BASE_URL}/analytics/users/count/payment`,
  GET_USERS_BY_PACKAGE: `${BASE_URL}/analytics/users/count/subscribed-package`,
  GET_LAB_CREDITS_COUNT: `${BASE_URL}/analytics/users/count/lab-credit`,
  GET_CLOUD_LABS_LIST: `${BASE_URL}/analytics/users/count/lab`,
  GET_COUPONS_LIST: `${BASE_URL}/analytics/coupons/count`,

  GET_COOUNTRY_LIST: `${BASE_URL}/geo/countries`,
  GET_PACKAGE_LIST: `${BASE_URL}/package/list`,

  GET_COUPONS_DATA: `${BASE_URL}/analytics/coupons/list`,

  GET_USERS_DATA: `${BASE_URL}/users/sales-users`,
  GET_ALL_USERS_DATA: `${BASE_URL}/users/list`,
  CREATE_USER: `${BASE_URL}/users/create-user`,
  UPDATE_SALES: `${BASE_URL}/users/update-sales`,
  GET_USER_PAYMENT_DETAILS: `${BASE_URL}/users/payment-link`,

  GET_USER_JOURNEY: `${BASE_URL}/userJourney/user-journey`,
  GET_USER_STORIES_USERS_DATA: `${BASE_URL}/userJourney/list-users`,
  GET_TRACKING_DATES: `${BASE_URL}/userJourney/journeys-dates`,

  GET_REFERRAL_COUNT: `${BASE_URL}/nasscom/total-count`,
  GET_REFERRAL_COUNT_BY_ID: `${BASE_URL}/nasscom/total-count-ref`,
  GET_DISTINCT_REFERRAL: `${BASE_URL}/nasscom/distinct/referredby`,

  GET_NOTIFICATIONS_LIST: `${BASE_URL}/notifications/list`,
  UPDATE_NOTIFICATIONS: `${BASE_URL}/notifications/mark-as-read/`,

  GET_SALES_USER_DATA: `${BASE_URL}/users/sales-user/`,

  UPDATE_DEMO_CALLS_DATA:`${BASE_URL}/demoCall/update/`,
  GET_DEMO_CALLS_DATA : `${BASE_URL}/demoCall/list`,

  GET_REVENUE_DATA:`${BASE_URL}/analytics/sales`,
};
