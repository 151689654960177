import React, { useEffect, useState } from "react";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import {
  DashboardBox,
  LearningStatus,
  ProgressBar,
  ProgressBarActiveRange,
  ProgressBarBox,
  TicketBox,
  Ticket,
  GraphTopBox,
  GraphContainer,
} from "./dashboard.styled";
import {
  requestGetUsers,
  requestGetRegisteredUsers,
  requestGetUsersByPaymentDetails,
  requestGetUsersByPackage,
  requestGetCountryList,
  requestGetPackageList,
  requestGetLabCoinsCount,
  requestGetLabsList,
  requestGetCouponsList
} from "../../redux/actions";
import { USER_TYPES, DASHBOARD_TYPES, GLOBAL_TYPES } from "../../redux/types";
import { Card, Modal, Button, ContentHeader, Dropdown, Loader } from "../../theme/components";
import TotalItemsImg from "../../assets/icons/totalItems.svg";
import TeamMembersImg from "../../assets/icons/teamMembers.svg";
import ActivePathImg from "../../assets/icons/activePath.svg";
import PendingImg from "../../assets/icons/pending.svg";
import { Line, Doughnut } from "react-chartjs-2";
import { Constant } from "../../helpers/constant.helper";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
} from 'chart.js'
//Regitering The Required Tools Used In Graph **
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
)
function Dashboard(props) {
  const [isUsersDetailFetching, setIsUsersDetailFetching] = useState(true);
  const [totalUsers, setTotalUsers] = useState({});
  const [totalUsersFilter, setTotalUsersFilter] = useState({});
  const [registeredUsers, setRegisteredUsers] = useState({});
  const [registeredUsersFilter, setregisteredUsersFilter] = useState("weekCount");
  const [usersDataByPayment, setUsersDataByPayment] = useState({});
  const [usersDataByPaymentFilter, setUsersDataByPaymentFilter] = useState("month");
  const [usersDataByPackage, setUsersDataByPackage] = useState({});
  const [countryDropdownList, setCountryDropdownList] = useState([]);
  const [packageDropdownList, setPackageDropdownList] = useState([]);
  const [totalCreditsCount, setTotalCreditsCount] = useState({});
  const [totalCreditsCountFilter, setTotalCreditsCountFilter] = useState("monthly");
  const [couponFilter, setCouponFilter] = useState("activeCouponsCount");
  const [labsList, setLabsList] = useState({});
  const [couponsData, setCouponsData] = useState({});
  const [filterOptions, setFilterOptions] = useState({});

  // * Api Calls On Render Dashboard *
  useEffect(() => {
    //ToGet The Users Data And Showing IN Graph  
    const requestUsersDetail = EventBus.on(
      USER_TYPES.GET_USERS_REQUEST,
      () => setIsUsersDetailFetching(true)
    );
    const usersDetailSuccess = EventBus.on(
      USER_TYPES.GET_USERS_SUCCESS,
      (res) => {
        if (res) {
          setTotalUsers(res);
          setIsUsersDetailFetching(false);
        } else {
          alert("No Data Found");
          setIsUsersDetailFetching(false);
        }
      }
    );
    const usersDetailFailure = EventBus.on(
      USER_TYPES.GET_USERS_FAILED,
      () => {
        setIsUsersDetailFetching(false);
      }
    );

    props.requestGetUsers();

    //Fetching Registered User Deatsils
    const requestRegisteredUsersDetail = EventBus.on(
      DASHBOARD_TYPES.GET_REGISTERED_USERS_REQUEST,
      () => { }
    );
    const registeredUsersDetailSuccess = EventBus.on(
      DASHBOARD_TYPES.GET_REGISTERED_USERS_SUCCESS,
      (res) => {
        setRegisteredUsers(res);
      }
    );
    const registeredUsersDetailFailure = EventBus.on(
      DASHBOARD_TYPES.GET_REGISTERED_USERS_FAILED,
      () => {

      }
    );

    props.requestGetRegisteredUsers();

    //Fetching User By Payment Deatsils
    const requestUsersByPayment = EventBus.on(
      DASHBOARD_TYPES.GET_USERS_BY_PAYMENT_DETAILS_REQUEST,
      () => { }
    );
    const getUsersByPaymentSuccess = EventBus.on(
      DASHBOARD_TYPES.GET_USERS_BY_PAYMENT_DETAILS_SUCCESS,
      (res) => {
        setUsersDataByPayment(res);
      }
    );
    const getUsersByPaymentFailure = EventBus.on(
      DASHBOARD_TYPES.GET_USERS_BY_PAYMENT_DETAILS_FAILED,
      () => {
      }
    );

    props.requestGetUsersByPaymentDetails();


    //Fetching User By Package Deatsils
    const getUsersByPackage = EventBus.on(
      DASHBOARD_TYPES.GET_USERS_BY_PACKAGE_REQUEST,
      () => { }
    );
    const getUsersByPackageSuccess = EventBus.on(
      DASHBOARD_TYPES.GET_USERS_BY_PACKAGE_SUCCESS,
      (res) => {
        setUsersDataByPackage(res);
      }
    );
    const getUsersByPackageFailure = EventBus.on(
      DASHBOARD_TYPES.GET_USERS_BY_PACKAGE_FAILED,
      () => {

      }
    );

    props.requestGetUsersByPackage({ packageId: 3 });

    //country List 
    const getCountryList = EventBus.on(
      GLOBAL_TYPES.GET_COUNTRY_LIST_REQUEST,
      () => { }
    );
    const getCountryListSuccess = EventBus.on(
      GLOBAL_TYPES.GET_COUNTRY_LIST_SUCCESS,
      (res) => {
        setCountryDropdownList(res?.data?.rows)

      }
    );
    const getCountryListFailure = EventBus.on(
      GLOBAL_TYPES.GET_COUNTRY_LIST_FAILED,
      () => {

      }
    );

    props.requestGetCountryList();

    //package List 
    const getPackageList = EventBus.on(
      GLOBAL_TYPES.GET_PACKAGE_LIST_REQUEST,
      () => { }
    );
    const getPackageSuccess = EventBus.on(
      GLOBAL_TYPES.GET_PACKAGE_LIST_SUCCESS,
      (res) => {
        setPackageDropdownList(res?.data?.items);
      }
    );
    const getPackageFailure = EventBus.on(
      GLOBAL_TYPES.GET_PACKAGE_LIST_FAILED,
      () => {

      }
    );

    props.requestGetPackageList();

    //Lab coins count
    const getLabCoinsCount = EventBus.on(
      DASHBOARD_TYPES.GET_LAB_CREDITS_COUNT_REQUEST,
      () => { }
    );
    const getLabCoinsCountSuccess = EventBus.on(
      DASHBOARD_TYPES.GET_LAB_CREDITS_COUNT_SUCCESS,
      (res) => {
        setTotalCreditsCount(res);
      }
    );
    const getLabCoinsCountFailure = EventBus.on(
      DASHBOARD_TYPES.GET_LAB_CREDITS_COUNT_FAILED,
      () => {

      }
    );

    props.requestGetLabCoinsCount();

    //Labs List
    const getLabsList = EventBus.on(
      DASHBOARD_TYPES.GET_CLOUD_LABS_LIST_REQUEST,
      () => { }
    );
    const getLabsListSuccess = EventBus.on(
      DASHBOARD_TYPES.GET_CLOUD_LABS_LIST_SUCCESS,
      (res) => {
        setLabsList(res);
      }
    );
    const getLabsListFailure = EventBus.on(
      DASHBOARD_TYPES.GET_CLOUD_LABS_LIST_FAILED,
      () => {

      }
    );

    props.requestGetLabsList();

    //Coupons List
    const getCouponsList = EventBus.on(
      GLOBAL_TYPES.GET_COUPONS_LIST_REQUEST,
      () => { }
    );
    const getCouponsListSuccess = EventBus.on(
      GLOBAL_TYPES.GET_COUPONS_LIST_SUCCESS,
      (res) => {
        setCouponsData(res);
      }
    );
    const getCouponsFailure = EventBus.on(
      GLOBAL_TYPES.GET_COUPONS_LIST_FAILED,
      () => {

      }
    );

    props.requestGetCouponsList();

    return () => {
      requestUsersDetail();
      usersDetailSuccess();
      usersDetailFailure();

      requestRegisteredUsersDetail();
      registeredUsersDetailSuccess();
      registeredUsersDetailFailure();

      requestUsersByPayment();
      getUsersByPaymentSuccess();
      getUsersByPaymentFailure();

      getUsersByPackage();
      getUsersByPackageSuccess();
      getUsersByPackageFailure();

      getCountryList();
      getCountryListSuccess();
      getCountryListFailure();

      getPackageList();
      getPackageSuccess();
      getPackageFailure();

      getLabCoinsCount();
      getLabCoinsCountSuccess();
      getLabCoinsCountFailure();

      getLabsList();
      getLabsListSuccess();
      getLabsListFailure();

      getCouponsList();
      getCouponsListSuccess();
      getCouponsFailure();
    };
  }, []);

  //Request Get Users data On Change DropDown Value
  useEffect(() => {
    props.requestGetUsers(totalUsersFilter);
  }, [totalUsersFilter]);

  // ChartJs Options And Customizations
  const option = {
    maintainAspectRatio: false,
    title: {
      text: 'User Count',
      display: true
    },
    responsive: true,
    plugins: {
      tooltip: {
        yAlign: 'bottom',
        displayColors: false,
        titleMarginBottom: 0,
        callbacks: {
          title: function () {
            return "";
          }
        },
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "gray"
        },
        grid: {
          color: "#FAFAFA",
        },
      },
      x: {
        grid: {
          color: "#FAFAFA",
        },
        ticks: {
          color: "gray",
        }
      }
    },
  };

  function clearFilter() {
    setTotalUsersFilter({})
    setFilterOptions({})
  }

  return (
    <DashboardBox>
      <Card className="mb-2">
        <GraphTopBox className="p-2 d-flex justify-content-between">
          <h3>Users</h3>
          <div className="graphtoprightbox">
            <Button outline small onClick={() => clearFilter()} >Clear</Button>
            <Dropdown style={{ minWidth: '200px' }}
              values={filterOptions?.country}
              options={countryDropdownList?.map((item) => {
                return { label: item?.name, value: item?.id }
              })}
              placeholder=" Country "
              onChange={(selectedValue) => {
                setFilterOptions({ ...filterOptions, country: selectedValue })
                setTotalUsersFilter({ ...totalUsersFilter, countryId: selectedValue[0]?.value })
              }}
            />
            <Dropdown style={{ minWidth: '120px' }} options={Constant.dropDownOption()?.genderFilterOptions}
              placeholder=" Gender "
              values={filterOptions?.gender}
              onChange={(selectedValue) => {
                setFilterOptions({ ...filterOptions, gender: selectedValue })
                setTotalUsersFilter({ ...totalUsersFilter, gender: selectedValue[0]?.value })
              }}
            />
            <Dropdown style={{ minWidth: '100px' }} options={Constant.dropDownOption()?.ageGroupOptions} placeholder="Age Group"
              values={filterOptions?.ageGroup}
              onChange={(selectedValue) => {
                setFilterOptions({ ...filterOptions, ageGroup: selectedValue })
                setTotalUsersFilter({ ...totalUsersFilter, ageGroup: selectedValue[0]?.value })
              }}
            />
            <Dropdown style={{ minWidth: '100px' }} options={Constant.dropDownOption()?.dateFilterOptions} placeholder=" Date "
              values={filterOptions?.date}
              onChange={(selectedValue) => {
                setFilterOptions({ ...filterOptions, date: selectedValue })
                setTotalUsersFilter({ ...totalUsersFilter, filter: selectedValue[0]?.value })
              }}
            />
          </div>
        </GraphTopBox>
        <GraphContainer>
          {isUsersDetailFetching ? <div>Loading ...</div> :
            <div className="row h-100">
              <div className="col p-2 col-lg-8 col-md-12">
                {/* Line Chart */}
                {totalUsers && totalUsers?.chartDataForDateVsUserCount &&
                  <Line data={totalUsers?.chartDataForDateVsUserCount} options={option} />
                }
              </div>
              <div className="col p-2 col-lg-4 col-md-12">
                {/* Doughnut Chart */}
                {totalUsers && totalUsers?.chartData &&
                  <Doughnut
                    data={totalUsers?.chartData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />}
              </div>
            </div>}
        </GraphContainer>
      </Card>
      <Card>
        <TicketBox>
          <Ticket color="lightBlue1">
            <div className="left-ticket">
              <img src={TotalItemsImg} alt="TotalItems" width="54" />
            </div>
            <div>
              <p>
                Total Users
              </p>
              <h3>{totalUsers?.totalUserCount}</h3>
            </div>
          </Ticket>
          <Ticket color="lightBlue1">
            <div className="left-ticket">
              <img src={TeamMembersImg} alt="TeamMembers" width="54" />
            </div>
            <div className="d-flex flex-column w-100">
              <p>User Registration</p>
              <div className="d-flex justify-content-between">
                <h3>{registeredUsers?.[registeredUsersFilter]}</h3>
                <Dropdown
                  searchable={false}
                  options={Constant.dropDownOption()?.userRergistrationOptions}
                  style={{ backgroundColor: "white" }}
                  values={[{ label: 'week', value: 3 }]}
                  onChange={(selectedValue) => {
                    setregisteredUsersFilter(selectedValue[0]?.value);
                  }}
                />
              </div>
            </div>
          </Ticket>
          <Ticket color="lightBlue1">
            <div className="left-ticket">
              <img src={ActivePathImg} alt="ActivePath" width="54" />
            </div>
            <div className="d-flex flex-column w-100">
              <p>Total Coupons </p>
              <div className="d-flex justify-content-between">
                <h3>{couponsData?.[couponFilter]}</h3>
                <Dropdown options={Constant.dropDownOption()?.CouponOptions}
                  style={{ backgroundColor: "white", minWidth: '100px' }}
                  searchable={false}
                  values={[{ label: 'Active', value: '' }]}
                  onChange={(selectedValue) => {
                    setCouponFilter(selectedValue[0]?.value);
                  }}
                />
              </div>
            </div>
          </Ticket>
          <Ticket color="lightBlue1">
            <div className="left-ticket">
              <img src={TeamMembersImg} alt="TeamMembers" width="54" />
            </div>
            <div className="d-flex flex-column w-100">
              <p>Packages</p>
              <div className="d-flex justify-content-between">
                <h3>{usersDataByPackage?.totalUserCount}</h3>
                <Dropdown options={packageDropdownList?.map((option) => {
                  return { label: option?.name, value: option?.id }
                })}
                  style={{ backgroundColor: "white", minWidth: '150px' }}
                  searchable={false}
                  values={[{ label: "Monthly Premium", value: '' }]}
                  onChange={(selectedValue) => {
                    props.requestGetUsersByPackage({ packageId: selectedValue[0]?.value });
                  }}
                />
              </div>
            </div>
          </Ticket>
          <Ticket color="lightBlue1">
            <div className="left-ticket">
              <img src={TeamMembersImg} alt="TeamMembers" width="54" />
            </div>
            <div className="d-flex flex-column w-100">
              <p>Total Payments</p>
              <div className="d-flex justify-content-between">
                <h3>{usersDataByPayment?.[usersDataByPaymentFilter]}</h3>
                <Dropdown options={Constant.dropDownOption()?.userPaymentOptions}
                  style={{ backgroundColor: "white", minWidth: '100px' }}
                  searchable={false}
                  values={[{ label: "Month", value: '' }]}
                  onChange={(selectedValue) => {
                    setUsersDataByPaymentFilter(selectedValue[0]?.value);
                  }}
                />
              </div>
            </div>
          </Ticket>
          <Ticket color="lightBlue1">
            <div className="left-ticket">
              <img src={PendingImg} alt="Pending" width="54" />
            </div>
            <div className="d-flex flex-column w-100" >
              <p>Total Lab Coins Consumed </p>
              <div className="d-flex justify-content-between">
                <h3>{totalCreditsCount?.[totalCreditsCountFilter]}</h3>
                <Dropdown options={Constant.dropDownOption()?.LabCoinsOptions}
                  style={{ marginLeft: "10px", backgroundColor: "white", minWidth: '100px' }}
                  searchable={false}
                  values={[{ label: "Monthly", value: '' }]}
                  onChange={(selectedValue) => {
                    setTotalCreditsCountFilter(selectedValue[0]?.value);
                  }}
                />
              </div>
            </div>
          </Ticket>
          {Object.entries(labsList).map(([key, value], index) =>
            <Ticket color="lightBlue1" key={key}>
              <div className="left-ticket">
                <img src={PendingImg} alt="Pending" width="54" />
              </div>
              <div>
                <p style={{ fontSize: "0.9rem" }}>{value[0]?.labName}</p>
                <h4>{value[0]?.count}</h4>
              </div>
            </Ticket>
          )}
        </TicketBox>
      </Card>
    </DashboardBox>
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  requestGetUsers,
  requestGetRegisteredUsers,
  requestGetUsersByPaymentDetails,
  requestGetUsersByPackage,
  requestGetCountryList,
  requestGetPackageList,
  requestGetLabsList,
  requestGetLabCoinsCount,
  requestGetCouponsList
})(Dashboard);