import axios from "axios";
import { Convert } from "./convert.helper";
import { LStorage } from "./localstorage.helper";

class ApiRequest {
  static headers() {
    const headers = { "Content-Type": "application/json" };
    const userData = LStorage.getUserData();
    if (userData?.accessToken)
      headers["Authorization"] = `Bearer ${userData?.accessToken}`;

    return headers;
  }

  static async error(message, status) {
    if (status === 401) {
      // TO DO clear your store
    }
    // TO DO add notify error alert box
    alert(message);
  }

  static apiCall(options) {
    options.headers = Object.assign(this.headers(), options.headers);

    return axios(options)
      .then((response) => Convert.toCamelCase(response))
      .catch((errorResponse) => {
        if (!options.silent) {
          const errors =
            errorResponse.response &&
            errorResponse.response.data &&
            errorResponse.response.data.errors;
          const error =
            errorResponse.response &&
            errorResponse.response.data &&
            errorResponse.response.data.error;
          let errorMessages = "";
          const errorCode =
            errorResponse.response && errorResponse.response.status;

          if (errors) {
            if (errors instanceof Array || errors.full_messages) {
              errorMessages = errors.full_messages
                ? errors.full_messages.join("\n") //_.join(errors.full_messages, '\n')
                : errors.join("\n"); //_.join(errors, '\n');
            } else {
              errorMessages = errors;
            }
          } else if (error) {
            errorMessages = error;
          } else {
            errorMessages =
              errorResponse?.response?.data?.message ||
              "Something went wrong!, please try again or contact support";
          }

          this.error(errorMessages, errorCode);
        }

        throw errorResponse;
      });
  }

  static fetch(options) {
    return this.apiCall(options);
  }
}

export { ApiRequest };
