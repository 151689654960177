import DatePicker from "react-datepicker";
import { CalenderBox, CalenderLabel, ErrorBox } from './calenderStyled'
import "react-datepicker/dist/react-datepicker.css";

export const Calender = ({ classNames, ...props }) => {
    return (
        <CalenderBox {...props} className={`${classNames}`} >
            {props?.label && <CalenderLabel>{props?.label}</CalenderLabel>}
            <DatePicker {...props} className={`react-calender-input`} showIcon={true} />
            {props?.errorMessage && <ErrorBox>{props?.errorMessage}</ErrorBox>}
        </CalenderBox>
    );
};