import { API_END_POINTS } from "../endpoints";
import { ApiRequest } from "../../helpers";
import { Convert } from "../../helpers/convert.helper";

export function getUsersData(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_USERS_DATA + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function createUser(params) {
  return ApiRequest.fetch({
    method: "post",
    url: API_END_POINTS.CREATE_USER,
    data: params,
  }).then((response) => response.data);
}

export function updateSales(params) {
  return ApiRequest.fetch({
    method: "post",
    url: API_END_POINTS.UPDATE_SALES,
    data: params,
  }).then((response) => response.data);
}

export function getUserPaymentDetails(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_USER_PAYMENT_DETAILS + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function getAllUsersData(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_ALL_USERS_DATA + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function getSalesUserData(queryData) {
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_SALES_USER_DATA +`${queryData}`,
  }).then((response) => response.data);
}