import { COUPON_TYPES } from "../types";

//getRegistered Users
export function requestGetCouponsData(payload) {
  return {
    type: COUPON_TYPES.GET_COUPONS_DATA_REQUEST,
    payload,
  };
}

export function getCouponsDataSuccess(payload) {
  return {
    type: COUPON_TYPES.GET_COUPONS_DATA_SUCCESS,
    payload,
  };
}

export function getCouponsDataFailure(payload) {
  return {
    type: COUPON_TYPES.GET_COUPONS_DATA_FAILED,
    payload,
  };
}
