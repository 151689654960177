import { ReactComponent as DashboardImg } from "../assets/icons/dashboard.svg";
import { ReactComponent as UserImg } from "../assets/icons/user.svg";
import { ReactComponent as CompanyImg } from "../assets/icons/company.svg";
import { ReactComponent as DemoCallImg } from "../assets/icons/clock.svg";

class Constant {
  static sidebar = () => {
    const sidebarData = [
      {
        icon: DashboardImg,
        name: "Dashboard",
        route: "/dashboard",
        key: "dashboard",
      },
      // {
      //   icon: CompanyImg,
      //   name: "Company",
      //   route: "",
      //   key: "company",
      //   childrens: [
      //     {
      //       icon: "",
      //       name: "Teams",
      //       route: "/company/teams",
      //       key: "teams",
      //     },
      //     {
      //       icon: "",
      //       name: "Members",
      //       route: "/company/members",
      //       key: "members",
      //     },
      //     {
      //       icon: "",
      //       name: "Invite Member",
      //       route: "/company/invitemember",
      //       key: "invitemember",
      //     },
      //   ],
      // },
      {
        icon: CompanyImg,
        name: "Coupons",
        route: "/coupon",
        key: "coupons",
      },
      {
        icon: UserImg,
        name: "Users",
        route: "/users",
        key: "users",
      },
      {
        icon: UserImg,
        name: "Referals",
        route: "/referals",
        key: "referals",
      },
      {
        icon: DemoCallImg,
        name: "Demo Call",
        route: "/demo",
        key: "demoCall",
      },
    ];
    return sidebarData;
  };

  static modules = () => {
    const MODULE_NAMES = {
      DASHBOARD: "DASHBOARD",
      CLOUDLABS: "CLOUDLABS",
      CERTIFICATIONS: "CERTIFICATIONS",
      TRAININGS: "TRAININGS",
      PAYMENTS: "PAYMENTS",
      PROFILE: "PROFILE",
      MYPRACTICE: "MYPRACTICE",
    };
    return MODULE_NAMES;
  };

  static urls = () => {
    return {
      image: "https://qwikappstorage.blob.core.windows.net/uploads",
    };
  };

  static dropDownOption = () => {
    const dropDownOptionData = {
      options: [
        {
          value: "sample",
          label: "Test",
        },
        {
          value: "sample2",
          label: "Test2",
        },
      ],
      genderFilterOptions: [
        {
          value: "male",
          label: "Male",
        },
        {
          value: "female",
          label: "Female",
        },
        {
          value: "other",
          label: "Others",
        },
        {
          value: "not-disclosed",
          label: "Not-Disclosed",
        },
      ],
      userRergistrationOptions: [
        {
          value: "todayCount",
          label: "Today",
        },
        {
          value: "weekCount",
          label: "Week",
        },
        {
          value: "monthCount",
          label: "Month",
        },
      ],
      dateFilterOptions: [
        {
          value: "today",
          label: "Today",
        },
        {
          value: "weekly",
          label: "Weekly",
        },
        {
          value: "monthly",
          label: "Monthly",
        },
        {
          value: "yearly",
          label: "Yearly",
        },
      ],
      userPaymentOptions: [
        {
          value: "today",
          label: "Today",
        },
        {
          value: "week",
          label: "Weekly",
        },
        {
          value: "month",
          label: "Monthly",
        },
        {
          value: "year",
          label: "Yearly",
        },
      ],
      packageFilterOptions: [
        {
          value: "monthly",
          label: "Monthly",
        },
        {
          value: "yearly",
          label: "Yearly",
        },
      ],
      ageGroupOptions: [
        {
          value: 19,
          label: "13-19",
        },
        {
          value: 29,
          label: "20-29",
        },
        {
          value: 39,
          label: "30-39",
        },
        {
          value: 49,
          label: "40-49",
        },
        {
          value: 59,
          label: "50-59",
        },
        {
          value: 69,
          label: "60-69",
        },
        {
          value: 79,
          label: "70-79",
        },
        {
          value: 89,
          label: "80-89",
        },
      ],
      LabCoinsOptions: [
        {
          value: "daily",
          label: "Daily",
        },
        {
          value: "weekly",
          label: "Weekly",
        },
        {
          value: "monthly",
          label: "Monthly",
        },
        {
          value: "yearly",
          label: "Yearly",
        },
      ],
      CouponOptions: [
        {
          value: "totalCouponsCount",
          label: "All",
        },
        {
          value: "activeCouponsCount",
          label: "Active",
        },
        {
          value: "notActiveCouponsCount",
          label: "Inactive",
        },
      ],
    };
    return dropDownOptionData;
  };
}

export { Constant };
