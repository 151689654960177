import { API_END_POINTS } from "../endpoints";
import { ApiRequest } from "../../helpers";
import { Convert } from "../../helpers/convert.helper";
import { encryptData } from "../../utility/utils";

export function signInUser(params) {
  return ApiRequest.fetch({
    method: "post",
    url: API_END_POINTS.SIGN_IN,
    data: params,
  }).then((response) => response.data);
}

export function verifyOtp(params) {
  return ApiRequest.fetch({
    method: "post",
    url: API_END_POINTS.OTP_VERIFY,
    data: params,
  }).then((response) => response.data);
}

export function getUsers(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_USERS + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function getNotifications(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_NOTIFICATIONS_LIST + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function updateNotifications(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "patch",
    url: API_END_POINTS.UPDATE_NOTIFICATIONS +`${queryData}`,
  }).then((response) => response.data);
}