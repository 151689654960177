import { API_END_POINTS } from "../endpoints";
import { ApiRequest } from "../../helpers";
import { Convert } from "../../helpers/convert.helper";

export function getUserStoriesUsersData(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_USER_STORIES_USERS_DATA + `${query && "?" + query}`,
  }).then((response) => response.data);
}
export function getTrackingDates(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_TRACKING_DATES + `${query && "?" + query}`,
  }).then((response) => response.data);
}
export function getUserJourney(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_USER_JOURNEY + `${query && "?" + query}`,
  }).then((response) => response.data);
}
