import { API_END_POINTS } from "../endpoints";
import { ApiRequest } from "../../helpers";
import { Convert } from "../../helpers/convert.helper";

export function getDemoCallsData(queryData) {
    const query = Convert.ObjectToQuery(queryData);
    return ApiRequest.fetch({
        method: "get",
        url: API_END_POINTS.GET_DEMO_CALLS_DATA + `${query && "?" + query}`,
    }).then((response) => response.data);
}

export function updateDemoCallsData(queryData) {
    const query = Convert.ObjectToQuery(queryData);
    return ApiRequest.fetch({
        method: "patch",
        url: API_END_POINTS.UPDATE_DEMO_CALLS_DATA + `${queryData?.id}`,
        data: queryData,
    }).then((response) => response.data);
}
