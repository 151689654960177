import { getDecryptedData } from "../../utility/utils";
import { USER_TYPES } from "../types";

export function requestSignInUser(payload) {
  return {
    type: USER_TYPES.SIGN_IN_REQUEST,
    payload,
  };
}

export function signInUserSuccess(payload) {
  if (payload && payload.data) {
    payload = getDecryptedData(payload.data);
  }
  return {
    type: USER_TYPES.SIGN_IN_SUCCESS,
    payload,
  };
}

export function signInUserFailure(payload) {
  return {
    type: USER_TYPES.SIGN_IN_FAILED,
    payload,
  };
}

export function signOutUserSuccess(payload) {
  return {
    type: USER_TYPES.SIGN_OUT_SUCCESS,
    payload,
  };
}

export function requestOtpVerify(payload) {
  return {
    type: USER_TYPES.OTP_VERIFY_REQUEST,
    payload,
  };
}

export function otpVerifySuccess(payload) {
  return {
    type: USER_TYPES.OTP_VERIFY_SUCCESS,
    payload,
  };
}

export function otpVerifyFailure(payload) {
  return {
    type: USER_TYPES.OTP_VERIFY_FAILED,
    payload,
  };
}

export function requestGetUsers(payload) {
  return {
    type: USER_TYPES.GET_USERS_REQUEST,
    payload,
  };
}

export function getUsersSuccess(payload) {
  return {
    type: USER_TYPES.GET_USERS_SUCCESS,
    payload,
  };
}

export function getUsersFailure(payload) {
  return {
    type: USER_TYPES.GET_USERS_FAILED,
    payload,
  };
}
// GET NOTIFICATIONS
export function requestGetNotifications(payload) {
  return {
    type: USER_TYPES.GET_USER_NOTIFICATIONS,
    payload,
  };
}

export function getNotificationsSuccess(payload) {
  return {
    type: USER_TYPES.GET_USER_NOTIFICATIONS_SUCCESS,
    payload,
  };
}

export function getNotificationsFailure(payload) {
  return {
    type: USER_TYPES.GET_USER_NOTIFICATIONS_FAILED,
    payload,
  };
}

// PATCH NOTIFICATIONS
export function requestUpdateNotifications(payload) {
  return {
    type: USER_TYPES.UPDATE_USER_NOTIFICATIONS,
    payload,
  };
}

export function updateNotificationsSuccess(payload) {
  return {
    type: USER_TYPES.UPDATE_USER_NOTIFICATIONS_SUCCESS,
    payload,
  };
}

export function updatetNotificationsFailure(payload) {
  return {
    type: USER_TYPES.UPDATE_USER_NOTIFICATIONS_FAILED,
    payload,
  };
}