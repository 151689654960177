export const COMPANY_TYPES = {
  GET_COMPANY_REQUEST: "GET_COMPANY_REQUEST",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  GET_COMPANY_FAILED: "GET_COMPANY_FAILED",

  UPDATE_COMPANY_REQUEST: "UPDATE_COMPANY_REQUEST",
  UPDATE_COMPANY_SUCCESS: "UPDATE_COMPANY_SUCCESS",
  UPDATE_COMPANY_FAILED: "UPDATE_COMPANY_FAILED",

  UPLOAD_COMPANY_IMAGE_REQUEST: "UPLOAD_COMPANY_IMAGE_REQUEST", 
  UPLOAD_COMPANY_IMAGE_SUCCESS: "UPLOAD_COMPANY_IMAGE_SUCCESS",
  UPLOAD_COMPANY_IMAGE_FAILED: "UPLOAD_COMPANY_IMAGE_FAILED",
};
