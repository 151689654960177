import { takeEvery, call, put } from "redux-saga/effects";
import { getCouponsDataFailure , getCouponsDataSuccess} from "../actions";
import { COUPON_TYPES } from "../types";
import { getCouponsData } from "../apis";

export function* watchCouponsRequests() {
    yield takeEvery(COUPON_TYPES.GET_COUPONS_DATA_REQUEST, requestGetCouponsData);
  }

  function* requestGetCouponsData(action) {
    try {
      const data = yield call(getCouponsData, action.payload);
      yield put(getCouponsDataSuccess(data));
    } catch (e) {
      yield put(getCouponsDataFailure(e));
    }
  }