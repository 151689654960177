import styled from "styled-components";
import { COLORS } from "../../colors";
export const StatusChipBox = styled.div`
padding:0 8px;
/* background-color: ${COLORS.lightBlue}; */
border: 1px solid gray;
font-size:0.8rem;
border-radius:50px;
width:fit-content;
height:fit-content;
font-weight:600;
`;