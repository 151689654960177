export const REFERRAL_TYPES = {
  GET_REFERRAL_COUNT_REQUEST: "GET_REFERRAL_COUNT_REQUEST",
  GET_REFERRAL_COUNT_SUCCESS: "GET_REFERRAL_COUNT_SUCCESS",
  GET_REFERRAL_COUNT_FAILED: "GET_REFERRAL_COUNT_FAILED",

  GET_REFERRAL_COUNT_ID_REQUEST: "GET_REFERRAL_COUNT_ID_REQUEST",
  GET_REFERRAL_COUNT_ID_SUCCESS: "GET_REFERRAL_COUNT_ID_SUCCESS",
  GET_REFERRAL_COUNT_ID_FAILED: "GET_REFERRAL_COUNT_ID_FAILED",

  GET_REFERRAL_DISTINCT_REQUEST: "GET_REFERRAL_DISTINCT_REQUEST",
  GET_REFERRAL_DISTINCT_SUCCESS: "GET_REFERRAL_DISTINCT_SUCCESS",
  GET_REFERRAL_DISTINCT_FAILED: "GET_REFERRAL_DISTINCT_FAILED",
};
