import React, { useEffect, useState } from 'react'
import { CouponBox } from './coupon.styled'
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { requestGetCouponsData } from '../../redux/actions'
import { COUPON_TYPES } from '../../redux/types';
import { ReactComponent as EditImg } from "../../assets/icons/book.svg";
import { ReactComponent as Question } from "../../assets/icons/question.svg";
import { StatusChip, Table, Card, ContentHeader, Modal, TableLoader } from "../../theme/components";
import moment from 'moment';
function Coupon(props) {
  const [show, setShow] = useState(false);
  const [isCouponsDataFetching, setIsCouponsDataFetching] = useState(true);
  const [couponsData, setCouponsData] = useState({});
  const [couponDetails, setCoupondetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  // * Api Calls On Render Coupons *
  useEffect(() => {
    //ToGet The Coupons Data 
    const requestCouponsData = EventBus.on(
      COUPON_TYPES.GET_COUPONS_DATA_REQUEST,
      () => { setIsCouponsDataFetching(true) }
    );
    const couponsDataSuccess = EventBus.on(
      COUPON_TYPES.GET_COUPONS_DATA_SUCCESS,
      (res) => {
        if (res) {
          setCouponsData(res);
          setIsCouponsDataFetching(false);
        }
      }
    );
    const couponsDataFailure = EventBus.on(
      COUPON_TYPES.GET_COUPONS_DATA_FAILED,
      () => {
        setIsCouponsDataFetching(false);
      }
    );

    getCouponsData();
    return () => {
      requestCouponsData();
      couponsDataSuccess();
      couponsDataFailure();
    }
  }, [])

  //On Page Change Api Request For New data
  useEffect(() => {
    getCouponsData();
  }, [currentPage])

  function getCouponsData() {
    props.requestGetCouponsData({ page: currentPage });
  }

  //Columns Declaration For The Coupons Table
  const columns = [
    {
      key: "code",
      title: "Coupon Code",
      render: (data, type, row) => {
        return <div className='couponData'>
          <div className={` ${type?.isActive ? 'green-notification' : 'red-notification'}`}></div>
          <span>{type?.code}</span>
        </div>
      }
    },
    {
      key: "discount",
      title: "Discount%",
      render: (data, type, row) => {
        return <span className='discount'>{type?.discount}</span>
      }
    },
    {
      key: "createdAt",
      title: "Created At",
      render: (data, type, row) => {
        return moment(type?.createdAt).format("YYYY-MM-DD")
      }
    },
    {
      key: "startDate",
      title: "Start Date",
      render: (data, type, row) => {
        return moment(type?.startDate).format("YYYY-MM-DD")
      }
    },
    {
      key: "expireDate",
      title: "Expire Date",
      render: (data, type, row) => {
        return moment(type?.expireDate).format("YYYY-MM-DD")
      }
    },
    {
      key: "package",
      title: "Package Name",
      render: (data, type, row) => {
        if (type?.package) {
          return type?.package?.name
        } else {
          return "N/A"
        }
      }
    },
    // {
    //   key: "isActive",
    //   title: "Status",
    //   render: (data, type, row) => {
    //     if (type?.isActive) {
    //       return <StatusChip />
    //     } else {
    //       return <StatusChip secondary />
    //     }
    //   }
    // },
    {
      key: "actions",
      title: "View",
      render: (data, type, row) => {
        console.log(type)
        return <div className='view-container'>
          <EditImg className={`editSvg ${type?.isActive ? 'green-notification' : 'red-notification'}`} onClick={() => {
            setCoupondetails(type)
            setShow(true)
          }} />
        </div>
      }
    }
  ];

  function toggle() {
    setShow(!show);
  }

  return (
    <CouponBox>
      <Modal
        show={show}
        onClose={toggle}
        title={<h4>Coupon Code details</h4>}
        maxWidth="50"
      >
        <div className='coupon-details mt-5 mx-5'>
          <h3>Code : <span style={{ borderRadius: '5px' }}>" {couponDetails?.code} "</span></h3>
          <h3>Live Date : <span>{moment(couponDetails?.startDate).format("YYYY-MM-DD")}</span></h3>
          <h3>Discount Percentage : <span>{couponDetails?.discount + "%"}</span></h3>
          <h3>Date Of Creation : <span>{moment(couponDetails?.createdAt).format("YYYY-MM-DD")}</span></h3>
          <h3>Date Of Expiration : <span>{moment(couponDetails?.expireDate).format("YYYY-MM-DD")}</span></h3>
          <h3>Package : <span>{couponDetails?.package ? couponDetails?.package?.name : 'n/a'}<Question className='question-icon' /> </span></h3>
        </div>
      </Modal>
      <ContentHeader
        title="Coupons"
      />
      <br />
      {
        isCouponsDataFetching ?
          <TableLoader isLoading={isCouponsDataFetching} /> :
          <Card>
            <Table
              totalItems={couponsData?.totalItems}
              columns={columns}
              data={couponsData?.items}
              totalPages={couponsData?.totalPages}
              pageNo={couponsData?.currentPage}
              onPageChange={(currentPage) => { setCurrentPage(currentPage) }}
            />
          </Card>
      }
    </CouponBox>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
export default connect(mapStateToProps, {
  requestGetCouponsData
})(Coupon);